<script setup lang="ts">
import { ScInlineList } from "@/sales/components/crud"
import { computed, ref } from "vue"
import { createModuleConfiguration } from "./customer-transactions.config"

const props = defineProps<{ customerId: string }>()
const config = computed(() => createModuleConfiguration(props.customerId))
const list = ref<typeof ScInlineList>()

defineExpose({ refresh: () => list.value?.refresh() })
</script>

<template>
    <ScInlineList v-bind="{ config }" ref="list"></ScInlineList>
</template>

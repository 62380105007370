import { $smt } from "@/common/util/format"
import { normalizeModuleConfiguration } from "@/vf"
import BonusCampaignCreate from "./BonusCampaignCreate.vue"

export function createModuleConfiguration() {
    return normalizeModuleConfiguration({
        apiBase: () => "/sales/bonus-token-campaign",
        mode: "in-table",
        edit: BonusCampaignCreate,
        new: BonusCampaignCreate,
        list: {
            onClick: null,
            fields: [
                {
                    name: "name",
                    title: "Name",
                    type: "string",
                    sortField: "a.name",
                },
                {
                    name: "tokenAmount",
                    title: "Token",
                    type: "string",
                    sortField: "a.tokenAmount",
                    getter: item => $smt(item.tokenAmount) + " SMT",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                },
                {
                    name: "validFrom",
                    title: "Gültig von",
                    type: "date",
                    sortField: "a.validFrom",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                },
                {
                    name: "validUntil",
                    title: "Gültig bis",
                    type: "date",
                    sortField: "a.validUntil",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                },
                {
                    name: "numberOfClaimsClaimed",
                    title: "Eingelöst",
                    type: "integer",
                    sortField: "a.numberOfClaimsClaimed",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                },
                {
                    name: "numberOfClaimsCreated",
                    title: "Anzahl Kunden",
                    type: "integer",
                    sortField: "a.numberOfClaimsCreated",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                },
            ],
        },
    })
}

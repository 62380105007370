<script setup lang="ts">
import type { Lead } from "@/model/app/lead"
import ScShow from "@/sales/components/crud/ScShow.vue"
import ScShowToolbar from "@/sales/components/crud/ScShowToolbar.vue"
import LeadShowAgent from "@/sales/components/LeadShowAgent.vue"
import ScTab from "@/sales/components/tabs/ScTab.vue"
import ScTabGroup from "@/sales/components/tabs/ScTabGroup.vue"
import { $date, useHttpClient, useLoadEntity } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { ref } from "vue"
import LeadAgentHistoryList from "./agent-history/LeadAgentHistoryList.vue"
import LeadAppointmentList from "./appointment/LeadAppointmentList.vue"
import { createModuleConfiguration } from "./leads.config"
import LeadNoteListWithEnter from "./note/LeadNoteListWithEnter.vue"

const { post } = useHttpClient()

const props = defineProps<{ id: string }>()
const config = createModuleConfiguration()
const { item, refresh } = await useLoadEntity<Lead>(props.id, config)

async function sendWelcomeMail() {
    await post(`/sales/lead/${item.value.id}/send-welcome-mail`)
    await refresh()
}

/**
 * Agent association / history
 */
const agentHistoryList = ref<typeof LeadAgentHistoryList>()

function refreshAgentList() {
    refresh()
    agentHistoryList.value?.refresh()
}
</script>

<template>
    <ScShow v-bind="{ config, item }">
        <template #default>
            <div class="flex justify-between items-center">
                <div>
                    <div class="card-title !mb-0">
                        {{ item.salutation }}
                        {{ item.fullName }}
                    </div>
                    <div class="italic opacity-50">geboren am {{ $date(item.birthdate) }}</div>
                </div>
                <div class="text-right">
                    <div class="italic opacity-50">erstellt am {{ $date(item.createdAt) }}</div>
                </div>
            </div>

            <div class="grid grid-cols-3 mt-10">
                <div>
                    <b>Adresse</b>
                    <div>{{ item.fullName }}</div>
                    <div>{{ item.company }}</div>
                    <div>{{ item.street }}</div>
                    <div>{{ item.zipCode }} {{ item.city }}</div>
                </div>

                <div>
                    <b>Telefon</b>
                    <div class="mb-2">
                        <a class="link" :href="`tel:${item.phone}`">{{ item.phone }}</a>
                    </div>

                    <b>E-Mail</b>
                    <div>
                        <a class="link" :href="`mailto:${item.email}`">{{ item.email }}</a>
                    </div>
                </div>

                <LeadShowAgent :item="item" :lead-id="item.id!" @agent-changed="refreshAgentList()"></LeadShowAgent>
            </div>

            <ScTabGroup class="mt-10">
                <ScTab title="Notizen">
                    <LeadNoteListWithEnter :lead-id="item.id!" />
                </ScTab>

                <ScTab title="Termine">
                    <LeadAppointmentList :lead="item" :lead-id="item.id!" @refresh="refresh" />
                </ScTab>

                <ScTab title="Agenten">
                    <LeadAgentHistoryList :lead-id="item.id!" ref="agentHistoryList" />
                </ScTab>
            </ScTabGroup>
        </template>
        <template #toolbar>
            <div class="flex">
                <ScShowToolbar :item="item" />
                <OutlineButton class="" @click="sendWelcomeMail()" v-if="!item.gotWelcomeMail">
                    Willkommens E-Mail senden
                </OutlineButton>
            </div>
        </template>
    </ScShow>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = withDefaults(
    defineProps<{
        bigger?: boolean
        color?: "primary" | "white" | "danger"
        type?: "button" | "submit" | "reset"
        disabled?: boolean
    }>(),
    {
        color: "primary",
        type: "button",
        disabled: false,
    },
)

const classes = computed(() => {
    const classes = [
        "inline-block",
        "rounded",
        "transition",
        "duration-300",
        "hover:shadow-lg",
        "font-bold",
        "disabled:opacity-50",
        "disabled:hover:shadow-none",
    ]

    switch (props.color) {
        // primary = primary on white bg
        case "primary":
            classes.push("bg-primary", "text-white", "hover:bg-primary-tint", "disabled:hover:bg-primary")
            break

        // white = white on primary bg
        case "white":
            classes.push("bg-white", "text-primary", "hover:bg-gray-100")
            break

        // danger = danger on white bg
        case "danger":
            classes.push("bg-red-600", "text-white", "hover:bg-red-700")
            break
    }

    classes.push(props.bigger ? "py-4 px-8" : "py-2 px-8")

    return classes
})
</script>

<template>
    <button :class="classes" :type="type" :disabled="disabled">
        <slot />
    </button>
</template>

<script setup lang="ts">
import ScList from "@/sales/components/crud/ScList.vue"
import { createModuleConfiguration } from "./agents.config"

const config = createModuleConfiguration()
</script>

<template>
    <ScList :config="config">
        <template #title>Agenten</template>
    </ScList>
</template>

<script lang="ts" setup>
import { injectMandatory, useModuleAction, type Entity } from "@/vf"

const item = injectMandatory<Entity>("item", "VfDeleteButton requires an item to be injected")
const moduleAction = useModuleAction()
</script>

<template>
    <button v-if="item?.id" type="button" class="btn btn-danger" @click="moduleAction.deleteEntity(item)">
        <span v-t="'crud:button.delete'"></span>
    </button>
</template>

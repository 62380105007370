<script setup lang="ts">
import { computed, toRefs } from "vue"
const props = defineProps<{ progress: number }>()
const { progress } = toRefs(props)

const radius = 43
const stroke = 3

const normalizedRadius = computed(() => radius - stroke / 2)
const circumference = computed(() => 2 * Math.PI * normalizedRadius.value)
</script>

<template>
    <div class="d-flex justify-items-center">
        <svg class="progress-ring" :height="radius * 2" :width="radius * 2">
            <circle
                class="progress-ring-static"
                :stroke-width="stroke"
                fill="transparent"
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius"
            />
            <circle
                class="progress-ring-circle"
                :style="{
                    'stroke-dasharray': circumference + 'px ' + circumference + 'px',
                    'stroke-dashoffset': circumference - (progress / 100) * circumference + 'px',
                }"
                :stroke-width="stroke"
                fill="transparent"
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius"
            />

            <text text-anchor="middle" alignment-baseline="baseline" :font-size="radius / 2">
                <tspan :x="radius" :y="radius * 1.18">{{ progress }} %</tspan>
            </text>
        </svg>
    </div>
</template>

<!--<style lang="scss" scoped>-->
<!--@import "@/styles/variables";-->

<!--vb-feedback-progress {-->
<!--    display: block;-->
<!--    text-align: center;-->

<!--    // to match other feedback elements:-->
<!--    height: 5em;-->
<!--    width: 5em;-->
<!--    margin: 1.25em auto 1.875em;-->
<!--    border: 0.25em solid transparent;-->
<!--    box-sizing: content-box;-->
<!--    transform: translate3d(-3px, -3px, 0);-->
<!--}-->

<!--.progress-ring {-->
<!--    margin: auto;-->

<!--    .progress-ring-circle {-->
<!--        transition: stroke-dashoffset 250ms;-->
<!--        transform: rotate(-90deg);-->
<!--        transform-origin: 50% 50%;-->
<!--        stroke: #3ac47d;-->
<!--    }-->

<!--    .progress-ring-static {-->
<!--        stroke: rgba(165, 220, 134, 0.3);-->
<!--    }-->

<!--    circle {-->
<!--        shape-rendering: geometricPrecision;-->
<!--    }-->

<!--    text {-->
<!--        fill: map_get($theme-colors, "success");-->
<!--    }-->
<!--}-->
<!--</style>-->

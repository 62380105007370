import { $smt } from "@/common/util/format"
import type { Transaction } from "@/model/app/transaction"
import { $currency, $shortdatetime, normalizeModuleConfiguration, type ModuleConfigurationOptions } from "@/vf"
import { computed, type Ref } from "vue"
import TransactionShowView from "./TransactionShowView.vue"

export function createModuleConfiguration(showOnlyMyLeads: Ref<boolean> | undefined = undefined) {
    const config: ModuleConfigurationOptions = {
        apiBase: () => "/sales/transaction",
        mode: "in-table",
        list: {
            fieldsCustomizable: true,
            requestParams: computed(() => ({ showOnlyMyLeads: showOnlyMyLeads?.value ? "1" : "0" })),
            orderField: "createdAt",
            orderDirection: "desc",
            newButton: false,
            fields: [
                { name: "customerNumber", title: "Kunden-Nr.", type: "string" },
                { name: "firstName", title: "Vorname", type: "string", sortField: "lead.firstName" },
                { name: "lastName", title: "Nachname", type: "string", sortField: "lead.lastName" },
                {
                    name: "state",
                    title: "Status",
                    type: "string",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) =>
                        ({ booked: "Gebucht", pending: "Reserviert", partial: "Teilgebucht" }[item.state!]),
                },
                {
                    name: "tokenAmount",
                    title: "SMT",
                    type: "vnode",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => $smt(item.tokenAmount ?? "0"),
                    sortField: "a.tokenAmount",
                },
                {
                    name: "bonusToken",
                    title: "Bonus",
                    type: "vnode",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => $smt(item.bonusToken ?? "0"),
                    sortField: "a.bonusToken",
                },
                {
                    name: "euroCentsAmount",
                    title: "Euro",
                    type: "vnode",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => $currency(item.euroCentAmount ?? "0"),
                    sortField: "a.euroCentAmount",
                },
                {
                    name: "createdAt",
                    sortField: "a.createdAt",
                    title: "Datum",
                    type: "string",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => $shortdatetime(item.createdAt),
                },
            ],
        },
        show: TransactionShowView,
    }

    return normalizeModuleConfiguration(config)
}

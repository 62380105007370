export function generateRandomString(
    length = 12,
    chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
) {
    let str = ""

    for (let i = 0; i < length; ++i) {
        str += chars[Math.floor(Math.random() * chars.length)]
    }

    return str
}

export function generatePassword() {
    return generateRandomString(12, "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789?!%_-.=;:()@+*#'$")
}

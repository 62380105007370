<script setup lang="ts">
import { VfList, type ModuleConfiguration } from "@/vf"
import { defineProps, ref, toRefs } from "vue"
import { ScListPagination, ScListTable } from "."

const props = defineProps<{ config: ModuleConfiguration }>()
const { config } = toRefs(props)

const list = ref<typeof VfList>()

defineExpose({
    refresh: (background = true) => list.value?.refresh(background),
})
</script>

<template>
    <VfList v-bind="{ config, await: false }" v-slot="{ listState, fields, filteredFieldGroups }" ref="list">
        <ScListTable v-bind="{ config, listState, fields, filteredFieldGroups }">
            <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
        </ScListTable>
        <ScListPagination class="mt-3" />
    </VfList>
</template>

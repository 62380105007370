<script setup lang="ts">
import { sleep, useHttpClient } from "@/vf"
import { useRouter } from "vue-router"
import UserIcon from "~icons/mdi/user"
import { useSalesUserStore } from "../stores/sales-user-store"
import SalesMenuItem from "./SalesMenuItem.vue"

const router = useRouter()
const userStore = useSalesUserStore()
const { post } = useHttpClient()
async function logout() {
    router.push({ name: "login" })
    await post("/sales/logout")
    await sleep(500)
    userStore.clear()
}
</script>

<template>
    <div class="bg-secondary text-white">
        <div class="flex flex-col min-h-screen pt-5 sticky top-0">
            <div class="flex flex-col items-center mb-5 mx-5">
                <div class="w-20 h-20 rounded-full bg-secondary-overlap grid place-items-center text-3xl mb-4">
                    <UserIcon />
                </div>
                <b>{{ userStore.firstName }} {{ userStore.lastName }}</b>
                <div class="text-modal text-xs mb-2">{{ userStore.agentCode }}</div>
            </div>

            <SalesMenuItem :to="{ name: 'dashboard' }">Dashboard</SalesMenuItem>
            <SalesMenuItem :to="{ name: 'calendar' }">Kalender</SalesMenuItem>
            <SalesMenuItem :to="{ name: 'customer-list' }">Kunden</SalesMenuItem>
            <SalesMenuItem :to="{ name: 'leads-list' }">Leads</SalesMenuItem>
            <SalesMenuItem :to="{ name: 'transaction-list' }">Aufträge</SalesMenuItem>
            <SalesMenuItem :to="{ name: 'agent-list' }" :check="u => !!u.isAdmin">Agenten</SalesMenuItem>
            <SalesMenuItem :to="{ name: 'token-price-list' }" :check="u => !!u.isAdmin">Preise</SalesMenuItem>
            <SalesMenuItem :to="{ name: 'bonus-campaign-list' }" :check="u => !!u.isAdmin">
                Bonus Kampagnen
            </SalesMenuItem>

            <div class="grow"></div>

            <RouterLink
                :to="{ name: 'change-password' }"
                class="mx-5 px-5 py-3 border-t border-secondary-overlap text-left"
            >
                Passwort ändern
            </RouterLink>
            <button @click="logout" class="mx-5 px-5 py-3 text-left">Abmelden</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSalesUserStore } from "@/sales/stores/sales-user-store"
import { sleep, useHttpClient } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OverlappingElement from "@/wallet/components/OverlappingElement.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import type { AxiosError } from "axios"
import { ref } from "vue"
import { useRouter } from "vue-router"

const username = ref("")
const password = ref("")

const state = ref<"input" | "fail">("input")

const { post } = useHttpClient()
const userStore = useSalesUserStore()
const router = useRouter()

async function submit() {
    try {
        await post("/sales/login", { username, password })
        await userStore.refresh()

        if (userStore.authenticated) {
            // emit("done")
            router.push({ name: "dashboard" })
            return
        }
    } catch (e) {
        const error = e as AxiosError<{ error: string }>
        const response = error.response

        if (response?.status === 406 && response?.data.error === "requiresPasswordChange") {
            // emit("request-change-password")
            router.push({ name: "reset-password" })
            return
        }

        state.value = "fail"
        await sleep(2000)
        state.value = "input"
    }
}
</script>

<template>
    <Transition :duration="500" appear>
        <OverlappingElement class="w-1/2 mx-auto">
            <template #under><img src="@/wallet/assets/icons/lock.svg" /></template>
            <template #default>
                <form class="" @submit.prevent="submit">
                    <RoundedCard color="modal" :class="{ 'animate-shake': state === 'fail' }">
                        <div class="card-title">
                            <b>Sales</b>
                            Login
                        </div>

                        <div
                            class="text-red-500 mt-2 opacity-0 transition translate-y-5 delay-500"
                            :class="{ 'opacity-100 !translate-y-0': state === 'fail' }"
                        >
                            Agentur Code oder Passwort falsch.
                        </div>

                        <div class="mt-5 mb-5">
                            <input type="text" v-model="username" placeholder="Agentur Code" class="input-field" />
                            <input type="password" v-model="password" placeholder="Passwort" class="input-field" />
                        </div>

                        <div class="mb-10">
                            <RouterLink :to="{ name: 'lost-password' }" class="opacity-50">
                                Passwort vergessen?
                            </RouterLink>
                        </div>

                        <FilledButton color="primary" class="w-full" type="submit">Anmelden</FilledButton>
                    </RoundedCard>
                </form>
            </template>
        </OverlappingElement>
    </Transition>
</template>

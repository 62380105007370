<script setup lang="ts">
import { computed } from "vue"
import { useSalesUserStore } from "../stores/sales-user-store"

const props = defineProps<{
    modelValue: boolean
}>()

const emit = defineEmits<{
    (e: "update:modelValue", modelValue: boolean): void
}>()

const userStore = useSalesUserStore()

const modelValue = computed({
    get() {
        return props.modelValue
    },
    set(value: boolean) {
        emit("update:modelValue", value)
    },
})
</script>

<template>
    <select
        v-model="modelValue"
        class="input-field !w-auto ml-2"
        :class="{ '!hidden': !userStore.isModerator && !userStore.isAdmin }"
    >
        <option :value="false">Zeige alle</option>
        <option :value="true">Zeige nur meine</option>
    </select>
</template>

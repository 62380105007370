<script lang="ts" setup>
import { useModuleAction } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"

const moduleAction = useModuleAction()
</script>

<template>
    <FilledButton @click="moduleAction.newEntity()" color="primary">Neu</FilledButton>
</template>

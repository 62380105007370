<script lang="ts" setup>
import CheckboxToggle from "@/sales/components/CheckboxToggle.vue"
import { injectMandatory, type ListState, type ModuleConfiguration } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { computed, type Ref } from "vue"

const listState = injectMandatory<ListState>("list-state", "ScListCustomizeFields: ListState is not provided")
const config = injectMandatory<Ref<ModuleConfiguration>>(
    "module-config",
    "ScListCustomizeFields: ModuleConfiguration is not provided",
)
const fieldGroups = computed(() => config.value.list.fieldGroups)

const showGroups = computed(() => fieldGroups.value.length > 1)
</script>

<template>
    <div>
        <div class="flex flex-row flex-wrap">
            <div v-for="group of fieldGroups" class="mr-5" :key="group.name">
                <CheckboxToggle
                    v-model="listState.customizeFields[group.name].enabled"
                    v-if="showGroups || !listState.customizeFields[group.name].enabled"
                >
                    <b>{{ group.title }}</b>
                </CheckboxToggle>

                <div v-for="field in group.fields" :key="field.name">
                    <CheckboxToggle v-model="listState.customizeFields[group.name].fields[field.name].enabled">
                        {{ field.title }}
                    </CheckboxToggle>
                </div>
            </div>
        </div>

        <div class="flex justify-between mt-4">
            <OutlineButton color="primary" @click="listState.resetCustomizeFields(true, true)">
                alle auswählen
            </OutlineButton>
            <OutlineButton color="primary" @click="listState.resetCustomizeFields(true)">
                Standard zurücksetzen
            </OutlineButton>
        </div>
    </div>
</template>

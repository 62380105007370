<script setup lang="ts"></script>

<template>
    <div class="grid items-center">
        <div class="grid-under text-secondary-overlap text-[20rem] font-black italic leading-none">
            <slot name="under"></slot>
        </div>
        <div class="grid-default grow">
            <slot />
        </div>
    </div>
</template>

<style scoped>
.grid {
    grid-template-columns: 1fr;
}

.grid-under {
    grid-column: 1;
    grid-row: 1;
    margin-left: -17%;
}

.grid-default {
    grid-column: 1;
    grid-row: 1;
}

.v-enter-from .grid,
.v-leave-from .grid,
.v-enter-from .grid,
.v-leave-from .grid {
    overflow-x: hidden;
    max-width: 100vw;
}

/**
  Enter animation
*/
.v-enter-active .grid-under {
    transition: all ease-in-out 500ms;
    transform: translateX(0);
    opacity: 1;
}

.v-enter-from .grid-under {
    transform: translateX(-100%);
    opacity: 0;
}

.v-enter-active .grid-default {
    transition: all ease-in-out 500ms;
    transform: translateX(0);
    opacity: 1;
}

.v-enter-from .grid-default {
    transform: translateX(100%);
    opacity: 0;
}

/**
  Leave animation
*/
.v-leave-active {
    transition: all ease-in-out 500ms;
    opacity: 1;
    transform: scale(1);
}
.v-leave-to {
    opacity: 0;
    transform: scale(0);
}
</style>

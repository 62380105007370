import { $smt } from "@/common/util/format"
import type { Transaction } from "@/model/app/transaction"
import { $currency, $shortdatetime, normalizeModuleConfiguration, type ModuleConfigurationOptions } from "@/vf"
import TransactionShowView from "../transaction/TransactionShowView.vue"

export function createModuleConfiguration(customerId: string) {
    const config: ModuleConfigurationOptions = {
        apiBase: () => `/sales/transaction/by-customer/${customerId}`,
        mode: "in-table",
        list: {
            fieldsCustomizable: true,
            orderField: "createdAt",
            orderDirection: "desc",
            defaultItemsPerPage: 10,
            fields: [
                {
                    name: "createdAt",
                    sortField: "a.createdAt",
                    title: "Datum",
                    type: "string",
                    getter: (item: Transaction) => $shortdatetime(item.createdAt),
                },
                {
                    name: "notice",
                    title: "Notiz",
                    type: "string",
                },
                {
                    name: "tokenAmount",
                    title: "SMT",
                    type: "vnode",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => $smt(item.tokenAmount ?? "0"),
                },
                {
                    name: "bonusToken",
                    title: "Bonus",
                    type: "vnode",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => $smt(item.bonusToken ?? "0"),
                },
                {
                    name: "euroCentAmount",
                    title: "Euro",
                    type: "vnode",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => $currency(item.euroCentAmount ?? "0"),
                },
                {
                    name: "state",
                    title: "Status",
                    type: "string",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (item: Transaction) => (item.state === "booked" ? "Gebucht" : "Reserviert"),
                },
            ],
        },
        show: TransactionShowView,
    }

    return normalizeModuleConfiguration(config)
}

<script setup lang="ts">
import { ref, watch } from "vue"

const props = defineProps<{
    modelValue?: string
}>()

const emit = defineEmits<{
    (e: "update:modelValue", value: string): void
}>()

watch(
    () => props.modelValue,
    () => {
        input.value = props.modelValue
    },
)

const input = ref(props.modelValue)
</script>

<template>
    <div class="auto-height-textarea" :data-replicated-value="input">
        <textarea
            class="input-field !m-0"
            v-model="input"
            @input="emit('update:modelValue', input ?? '')"
            v-bind="$attrs"
        ></textarea>
    </div>
</template>

<style scoped>
/* stolen from https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */

.auto-height-textarea {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
}
.auto-height-textarea::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.auto-height-textarea > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
}
.auto-height-textarea > textarea,
.auto-height-textarea::after {
    /* Identical styling required!! */
    padding: 1rem;
    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}
</style>

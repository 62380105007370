<script setup lang="ts">
import { ref, unref, watch, type Ref } from "vue"

type ModelValueType = boolean | any[]

const props = withDefaults(
    defineProps<{
        value?: any
        modelValue?: ModelValueType
        inline?: boolean
        label?: any
        // used for FormKit binding
        context?: any
        disabled?: boolean
    }>(),
    {
        inline: false,
        disabled: false,
    },
)

// get the state from the props if things have changed
function determineStateByProps() {
    return unref(props.context?.node?._value) ?? Array.isArray(props.modelValue)
        ? (props.modelValue as any[]).includes(props.value)
        : props.modelValue ?? false
}

const checked: Ref<boolean> = ref(determineStateByProps())

watch(
    () => props.modelValue,
    () => {
        checked.value = determineStateByProps()
    },
)

const emit = defineEmits<{
    (e: "change", value: ModelValueType): void
    (e: "update:modelValue", value: ModelValueType): void
}>()

function onInput(checked: boolean) {
    let newValue: ModelValueType

    if (Array.isArray(props.modelValue)) {
        // handle array bindings
        newValue = props.modelValue.slice()

        if (checked) {
            newValue.push(props.value)
        } else {
            newValue.splice(newValue.indexOf(props.value), 1)
        }
    } else {
        // handle boolean bindings
        newValue = checked
    }

    emit("update:modelValue", newValue)
    emit("change", newValue)
    props.context?.node.input(checked)
}
</script>

<template>
    <div class="" :class="{ 'd-inline-block': inline }">
        <label class="relative inline-flex items-center cursor-pointer">
            <input
                type="checkbox"
                class="sr-only peer"
                @input="onInput(($event.target as HTMLInputElement).checked)"
                v-bind="{ checked, value, disabled }"
            />
            <div
                class="mr-2 w-11 h-6 peer-focus:outline-none rounded-full peer bg-secondary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white"
            ></div>
            <slot><span v-t="label" v-if="label"></span></slot>
        </label>
    </div>
</template>

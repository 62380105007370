<script setup lang="ts">
import ScShowToolbar from "@/sales/components/crud/ScShowToolbar.vue"
import type { Entity, ModuleConfiguration } from "@/vf"
import { provide } from "vue"
import { ScBackButton } from "."

const props = withDefaults(
    defineProps<{
        config: ModuleConfiguration
        item: Entity
        header?: boolean
        toolbar?: boolean
    }>(),
    {
        header: true,
        toolbar: true,
    },
)

provide("module-config", props.config)
provide("item", props.item)
provide("current-page", "show")

const mode = props.config.show.page(props.item).mode
</script>

<template>
    <div class="">
        <div class="" v-if="header && mode === 'router'">
            <slot name="header">
                <div class="">
                    <ScBackButton></ScBackButton>
                    <h1 class="card-title">{{ config.readableName.entity(item) }}</h1>
                    <h2>{{ $t(config.readableName.plural) }}</h2>
                </div>
            </slot>
        </div>
        <div v-if="toolbar && mode === 'router'">
            <slot name="toolbar">
                <ScShowToolbar :item="item" />
            </slot>
        </div>
        <div>
            <slot :item="item"></slot>

            <template v-if="toolbar && mode !== 'router'">
                <div class="mt-10"></div>
                <slot name="toolbar">
                    <ScShowToolbar :item="item" />
                </slot>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ListResponse, NormalizedListFieldGroupConfiguration } from "@/vf"
import { createListState, useHttpClient } from "@/vf"
import type { ListState } from "@/vf/components/crud/list/types"
import { createInlineTable } from "@/vf/composables/useInlineTable"
import { useModuleAction } from "@/vf/module-action"
import type { ListFieldConfiguration, ListRequestParams, ModuleConfiguration } from "@/vf/module-configuration"
import type { AxiosResponse } from "axios"
import { computed, provide, toRefs, watch, watchEffect } from "vue"

const props = withDefaults(
    defineProps<{
        config: ModuleConfiguration
        await?: boolean
        listState?: ListState
    }>(),
    {
        await: true,
        listState: undefined,
    },
)

const { config } = toRefs(props)
watch(config, () => refresh())

const listState = props.listState ?? createListState(config)
listState.hook({ refresh, columnClicked, deleteEntity, editEntity, resetCustomizeFields })

defineExpose({
    refresh,
})

const emit = defineEmits<{
    (e: "loaded", params: { listState: ListState; response: AxiosResponse }): void
}>()

const inlineTable = createInlineTable({
    refresh: () => refresh(true),
})

const http = useHttpClient()
const moduleAction = useModuleAction(config.value, inlineTable)

provide("module-config", config)
provide("list-state", listState)

/*─────────────────────────────────────┐
│  list state / data                   |
└─────────────────────────────────────*/
function saveListState() {
    sessionStorage.setItem(
        `list-state-${config.value.list.id()}`,
        JSON.stringify({
            searchValue: listState.searchValue,
            orderField: listState.orderField,
            orderDirection: listState.orderDirection,
            page: listState.page,
            filter: listState.filter,
            customizeFields: listState.customizeFields,
            //'additionalListState': config.value.list.additionalListState,
        }),
    )
    localStorage.setItem(
        `list-state-${config.value.list.id()}`,
        JSON.stringify({
            itemsPerPage: listState.itemsPerPage,
            customizeFields: listState.customizeFields,
        }),
    )
}

function loadListState() {
    const local = JSON.parse(localStorage.getItem(`list-state-${config.value.list.id()}`) ?? "{}")
    const session = JSON.parse(sessionStorage.getItem(`list-state-${config.value.list.id()}`) ?? "{}")
    const loadedState = { ...local, ...session }

    for (const key of Object.keys(listState)) {
        if (key in loadedState) {
            // @ts-ignore
            listState[key] = loadedState[key]
        }
    }
}

function refresh(background = false) {
    if (!background) {
        listState.loading = true
    }
    saveListState()

    const loadFn = props.await ? http.get : http.getBg

    const { url, params } = config.value.list.onRequest({ url: config.value.apiBase(), params: getListParams() })

    return loadFn<ListResponse>(url, params).then((response: AxiosResponse<ListResponse>) => {
        listState.list = response.data.list
        listState.totalItems = response.data.totalItems
        listState.pageCount = response.data.pageCount || 1 // on empty list show "page 1 of 1" instead of "page 1 of 0"
        listState.page = response.data.page

        emit("loaded", { listState, response })
        listState.loading = false

        if (listState.page > listState.pageCount && listState.pageCount > 0) {
            listState.page = 1
            refresh()
        }
    })
}

function getListParams(): ListRequestParams {
    // let orderBy = getField(listState.orderField, "sortField")

    const orderBy = fields.value.find(field => field.name === listState.orderField)?.["sortField"]
    return {
        search: listState.searchValue,
        itemsPerPage: listState.itemsPerPage,
        sort: orderBy,
        direction: listState.orderDirection,
        page: listState.page,
        filter: listState.filter,
        ...config.value.list.requestParams.value,
    }
}

loadListState()

// reload list when certain states change
watch(
    () => [
        listState.page,
        listState.itemsPerPage,
        listState.orderField,
        listState.orderDirection,
        config.value.list.requestParams.value,
    ],
    async () => await refresh(true),
)
watch(
    () => listState.filter,
    async () => await refresh(),
    { deep: true },
)
watch(
    () => listState.customizeFields,
    () => saveListState(),
    { deep: true },
)

let searchDebounce: ReturnType<typeof setTimeout>
watch(
    () => listState.searchValue,
    () => {
        if (searchDebounce) {
            clearTimeout(searchDebounce)
        }
        searchDebounce = setTimeout(() => refresh(), 500)
    },
)

/*─────────────────────────────────────┐
│  user interaction                    │
└─────────────────────────────────────*/
function columnClicked(
    item: any,
    field: ListFieldConfiguration | "button:show",
    fieldGroup: NormalizedListFieldGroupConfiguration | undefined,
) {
    const clickResult = config.value.list.onClick(item, typeof field === "string" ? field : field.name, fieldGroup)

    if (clickResult) {
        clickResult.highlightKey ??= fieldGroup?.highlightKey ?? "default"
        moduleAction.toggle(item, clickResult)
    }
}

function editEntity(item: any) {
    moduleAction.toggle(item, "edit")
}

function deleteEntity(item: any) {
    moduleAction.deleteEntity(item)
}

/*─────────────────────────────────────┐
│  rendering                           │
└─────────────────────────────────────*/
const fields = computed(() => config.value.list.fieldGroups.flatMap(group => group.fields))

/*─────────────────────────────────────┐
│   customize list fields              │
└─────────────────────────────────────*/
watchEffect(() => {
    resetCustomizeFields(false)
})

function resetCustomizeFields(overrideCurrentSettings = true, forceAllTrue = false) {
    for (const group of config.value.list.fieldGroups) {
        if (!(group.name in listState.customizeFields) || overrideCurrentSettings) {
            listState.customizeFields[group.name] = {
                enabled: !group.fields.reduce((acc, field) => acc && !!field.defaultHidden, false) || forceAllTrue,
                fields: {},
            }
        }
        for (const field of group.fields) {
            if (!(field.name in listState.customizeFields[group.name].fields) || overrideCurrentSettings) {
                listState.customizeFields[group.name].fields[field.name] = {
                    enabled: !field.defaultHidden || forceAllTrue,
                }
            }
        }
    }
}

const filteredFieldGroups = computed(() => {
    const filteredFieldGroups = []

    for (const fieldGroup of config.value.list.fieldGroups) {
        if (!listState.customizeFields[fieldGroup.name].enabled) {
            // whole group is disabled
            continue
        }

        // filter disabled fields
        const currentFieldGroup = {
            ...fieldGroup,
            fields: fieldGroup.fields.filter(
                field => listState.customizeFields[fieldGroup.name].fields[field.name].enabled,
            ),
        }

        // hide groups where all fields are hidden as if the whole group was hidden
        if (!currentFieldGroup.fields.length) {
            continue
        }

        filteredFieldGroups.push(currentFieldGroup)
    }

    return filteredFieldGroups
})
if (props.await) {
    await refresh()
} else {
    refresh()
}
</script>

<template>
    <slot
        v-bind="{
            config,
            listState,
            fields,
            filteredFieldGroups,
            inlineTable,
            moduleAction,
        }"
    ></slot>
</template>

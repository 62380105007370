<script setup lang="ts">
import type { Customer } from "@/model/app/customer"
import { ScEdit } from "@/sales/components/crud"
import { useLoadEntity, VfFormRow } from "@/vf"
import { createModuleConfiguration } from "./customers.config"

const props = defineProps<{ id: string }>()
const config = createModuleConfiguration()
const { item } = await useLoadEntity<Customer>(props.id, config)
</script>

<template>
    <ScEdit v-bind="{ config, item }">
        <div class="grid grid-cols-2 gap-5">
            <VfFormRow name="salutation" />
            <VfFormRow name="title" />
        </div>

        <div class="grid grid-cols-2 gap-5">
            <VfFormRow name="firstName" />
            <VfFormRow name="lastName" />
        </div>

        <VfFormRow name="company" />

        <VfFormRow name="birthdate" />
        <VfFormRow name="street" />

        <div class="grid grid-cols-2 gap-5">
            <VfFormRow name="zipCode" />
            <VfFormRow name="city" />
        </div>
    </ScEdit>
</template>

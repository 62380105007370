import {
    useDialogs,
    useDialogsStore,
    useHttpClient,
    useInlineTable,
    useToaster,
    type Entity,
    type ModuleConfiguration,
    type NormalizedCrudViewModelInterface,
} from "@/vf"

import type { InlineTable } from "@/vf/composables/useInlineTable"
import type { AfterSaveActionValues } from "@/vf/module-configuration/types"
import { inject, unref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { vfConfig } from "./config/VfConfig"

export type PageType = "new" | "show" | "edit" | NormalizedCrudViewModelInterface

export function useModuleAction(moduleConfiguration?: ModuleConfiguration, overrideInlineTable?: InlineTable) {
    const i18n = useI18n()
    const router = useRouter()
    if (!moduleConfiguration) {
        moduleConfiguration = inject("module-config")
    }
    moduleConfiguration = unref(moduleConfiguration)
    if (!moduleConfiguration) {
        throw new Error("No module configuration found")
    }
    const config = moduleConfiguration!
    const inlineTable = overrideInlineTable ?? useInlineTable(true)

    const execute = async (
        entity: any,
        page: PageType,
        action: "open" | "close" | "toggle" = "open",
        rowId: string | undefined = undefined,
    ) => {
        let cvm: NormalizedCrudViewModelInterface

        if (typeof page === "string") {
            if (action == "close" && page == "edit" && config[page].page(entity).mode === "router") {
                // close edit -> open show
                action = "open"
                cvm = config["show"].page(entity)
            } else {
                cvm = config[page].page(entity)
            }
        } else {
            cvm = page
        }

        if (!cvm) {
            // action returned null
            return
        }
        rowId ??= cvm?.rowId!

        switch (cvm.mode) {
            case "in-table": {
                if (!inlineTable) {
                    throw new Error("No inline table found")
                }
                const component = config.resolveComponent(cvm, entity)

                if (component === null) {
                    inlineTable!.close()
                    return
                }

                if (action == "open" || (action == "toggle" && !inlineTable!.rowIsOpen(rowId, component))) {
                    inlineTable!.open(cvm.props, component, rowId, cvm.highlightKey)
                } else {
                    inlineTable!.close()
                }

                break
            }

            case "router": {
                const routePrefix = config.routePrefix()
                if (action == "close") {
                    await router.push({
                        name: routePrefix + ".index",
                    })
                } else {
                    await router.push({
                        name: routePrefix + "." + cvm.routePart,
                        params: cvm.props,
                    })
                }

                break
            }

            case "dialog": {
                if (action === "close") {
                    const dialogStore = useDialogsStore()
                    dialogStore.dialogs.forEach(ctrl => {
                        console.log("executeAfterAction: close dialog", ctrl)
                        ctrl.cancel(undefined)
                    })

                    return
                }

                const dialogs = useDialogs()

                if (!cvm.component) {
                    console.error("Mode 'dialog' given but no 'component' has been specified.")
                    return
                }

                if (typeof cvm.component === "string") {
                    console.error(
                        `When mode is set to 'dialog', component must be an Component instance. String "${cvm.component}" given`,
                    )
                    return
                }

                dialogs
                    .open({
                        component: cvm.component,
                        props: cvm.props,
                        locked: false,
                    })
                    .whenClosed(() => {
                        inlineTable?.emit("refresh")
                    })

                break
            }
        }
    }

    // const getValueOrCall = (property: string | ((...args: any[]) => string), ...params: any[]) => {
    //     if (typeof property === "function") {
    //         return property(...params)
    //     }

    //     return property
    // }

    const executeAfterAction = (afterAction: AfterSaveActionValues, entity: Entity, saved = false, deleted = false) => {
        if (afterAction === "auto") {
            if (inlineTable || config["show"].page(entity).mode === "dialog") {
                afterAction = "close"
            } else {
                afterAction = deleted ? "navigate-list" : "navigate-show"
            }
        }

        if (afterAction === "close-dialog") {
            console.warn("please change close-dialog to close")
            afterAction = "close"
        }

        if (afterAction === "close" || afterAction === "navigate-list") {
            if (inlineTable) {
                inlineTable!.close({ closedByView: true, saved, deleted })
            } else {
                return execute(entity, "show", "close")
            }
        } else if (afterAction === "navigate-show") {
            return execute(entity, "show", "open")
        } else if (typeof afterAction === "function") {
            afterAction(entity)
        }
    }

    return {
        execute,
        executeAfterAction,
        open(entity: Entity, page: PageType) {
            return execute(entity, page, "open")
        },
        close(entity: Entity, page: PageType) {
            return execute(entity, page, "close")
        },
        toggle(entity: Entity, page: PageType) {
            return execute(entity, page, "toggle")
        },
        showEntity(entity: Entity) {
            return execute(entity, "show", "open")
        },
        editEntity(entity: Entity) {
            return execute(entity, "edit", "open")
        },
        newEntity() {
            return execute(null, "new", "toggle", "new")
        },
        copyEntity(originalEntity: Entity) {
            const cvm: NormalizedCrudViewModelInterface = config["new"].page()
            cvm.props = {
                originalEntity,
            }

            return execute(null, cvm, "open", "new")
        },
        saved(entity: Entity) {
            return executeAfterAction(config.afterSaveAction, entity, true)
        },
        async deleteEntity(entity: Entity): Promise<void> {
            const callback = async () => {
                await useHttpClient().delete(config.entityPath(entity))

                useToaster().add(
                    "success",
                    i18n.t("crud:delete.success.title", { type: config.readableName.singular }),
                    i18n.t("crud:delete.success.message", {
                        type: config.readableName.singular,
                        name: config.readableName.entity(entity),
                        interpolation: { escapeValue: false },
                    }),
                )

                return executeAfterAction(config.afterDeleteAction, entity, false, true)
            }

            if (config.safeDeleteDialog.active) {
                const response = await useDialogs().open({
                    component: vfConfig.safeDeleteDialog.component(config, entity),
                    props: {
                        title: i18n.t("crud:delete.confirmation"),
                        message: config.safeDeleteDialog.prompt(entity),
                        requiredValue: config.safeDeleteDialog.confirmationProperty(entity),
                    },
                })

                if (!response.wasCancelled) {
                    await callback()
                }

                return
            }

            if (
                await useDialogs().confirm("crud:delete.confirmation_detail", {
                    type: config.readableName.plural,
                    name: config.readableName.entity(entity),
                })
            ) {
                await callback()
            }
        },
    }
}

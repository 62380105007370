import type { Agent } from "@/model/app/agent"
import { $agentRole } from "@/sales/util/formatter"
import AgentEditView from "@/sales/views/agent/AgentEditView.vue"
import AgentShowView from "@/sales/views/agent/AgentShowView.vue"
import { normalizeModuleConfiguration, type ModuleConfigurationOptions } from "@/vf"

export function createModuleConfiguration() {
    const config: ModuleConfigurationOptions = {
        apiBase: () => "/sales/agent",
        mode: "in-table",
        list: {
            fieldsCustomizable: true,
            fields: [
                { name: "agentCode", title: "Code", type: "string" },
                { name: "firstName", title: "Vorname", type: "string" },
                { name: "lastName", title: "Nachname", type: "string" },
                {
                    name: "primaryRole",
                    title: "Rolle",
                    type: "string",
                    getter: (i: Agent) => $agentRole(i.primaryRole),
                },
                { name: "moderatorAgentCode", title: "Moderator", type: "string" },
            ],
        },
        show: AgentShowView,
        edit: AgentEditView,
        new: AgentEditView,
    }

    return normalizeModuleConfiguration(config)
}

<script setup lang="ts">
import ChangePasswordCard from "@/common/components/ChangePasswordCard.vue"
import { useRouter } from "vue-router"
import PageHeader from "../components/PageHeader.vue"

const router = useRouter()
</script>

<template>
    <div>
        <PageHeader>
            <template #title>Passwort ändern</template>
        </PageHeader>
        <ChangePasswordCard url="/sales/user/change-password" @done="router.push({ name: 'dashboard' })" />
    </div>
</template>

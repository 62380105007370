<script setup lang="ts">
import { ScInlineList } from "@/sales/components/crud"
import { computed, ref } from "vue"
import { createModuleConfiguration } from "./notes.config"

const props = defineProps<{ leadId: string }>()
const config = computed(() => createModuleConfiguration(props.leadId))

const list = ref<typeof ScInlineList>()

defineExpose({
    refresh: (background = true) => list.value?.refresh(background),
})
</script>

<template>
    <ScInlineList :config="config" ref="list"></ScInlineList>
</template>

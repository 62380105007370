export function $currency(value: number) {
    return Intl.NumberFormat("de", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value / 100)
}

export function $smt(value: string): string {
    // reformat an input value like 12345.67890 to 12.345,67 with maximal 2 decimal places, rounding up
    // (c) copilot
    const parts: string[] = value.split(".")
    const integerPart: string = parts[0]
    const decimalPart: string = parts[1] ?? "00"
    const decimalPartRounded: string = decimalPart.length > 2 ? decimalPart.substring(0, 2) : decimalPart
    const decimalPartRoundedUp: string = decimalPart.length > 2 ? decimalPart.substring(2, 3) : "0"

    if (decimalPartRoundedUp === "5") {
        const lastDecimalDigit: number = parseInt(decimalPartRounded.substring(1, 2))
        const newLastDecimalDigit: number = lastDecimalDigit + 1

        return `${integerPart},${decimalPartRounded.substring(0, 1)}${newLastDecimalDigit}`
    }

    const integerPartWithThounsandSeparator = Intl.NumberFormat("de", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(parseInt(integerPart))

    return `${integerPartWithThounsandSeparator},${decimalPartRounded}`
}

export function isNumericKey(event: KeyboardEvent): void {
    const keysAllowed: string[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", ","]
    const keyPressed: string = event.key

    if (!keysAllowed.includes(keyPressed) && keyPressed !== "Enter") {
        event.preventDefault()
    }
}

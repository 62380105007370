import { createRouter, createWebHistory } from "vue-router"
import { useSalesUserStore, type SalesUserStore } from "../stores/sales-user-store"
import AgentListView from "../views/agent/AgentListView.vue"
import BonusCampaignsView from "../views/bonus-campaign/BonusCampaignsView.vue"
import CalendarView from "../views/CalendarView.vue"
import ChangePasswordView from "../views/ChangePasswordView.vue"
import CustomerListView from "../views/customer/CustomerListView.vue"
import DashboardView from "../views/DashboardView.vue"
import LeadListView from "../views/lead/LeadListView.vue"
import EnterPasswordView from "../views/login/EnterPasswordView.vue"
import LostPasswordView from "../views/login/LostPasswordView.vue"
import ResetPasswordView from "../views/login/ResetPasswordView.vue"
import LoginView from "../views/LoginView.vue"
import TokenPriceListView from "../views/token-price/TokenPriceListView.vue"
import TransactionListView from "../views/transaction/TransactionListView.vue"

const router = createRouter({
    history: createWebHistory(import.meta.env.DEV ? "/sales" : "/"),
    routes: [
        {
            path: "/login",
            component: LoginView,
            name: "login-layout",
            meta: { hideLayout: true },
            children: [
                {
                    path: "/login",
                    name: "login",
                    component: EnterPasswordView,
                    meta: { loginTransition: true },
                },
                {
                    path: "/passwort-vergessen",
                    name: "lost-password",
                    component: LostPasswordView,
                    meta: { loginTransition: true },
                },
                {
                    path: "/passwort-zuruecksetzen",
                    name: "reset-password",
                    component: ResetPasswordView,
                    meta: { loginTransition: true },
                },
                {
                    path: "/passwort-zuruecksetzen/:token",
                    name: "reset-password-code",
                    component: ResetPasswordView,
                    props: true,
                    meta: { loginTransition: true },
                },
            ],
        },
        {
            path: "/",
            name: "dashboard",
            component: DashboardView,
            meta: { isGranted: (u: SalesUserStore) => u.isAgent },
        },
        {
            path: "/passwort-aendern",
            name: "change-password",
            component: ChangePasswordView,
            meta: { isGranted: (u: SalesUserStore) => u.isAgent },
        },
        {
            path: "/kunden",
            name: "customer-list",
            component: CustomerListView,
            meta: { isGranted: (u: SalesUserStore) => u.isAgent },
        },
        {
            path: "/kalender",
            name: "calendar",
            component: CalendarView,
            meta: { isGranted: (u: SalesUserStore) => u.isAgent },
        },
        {
            path: "/leads",
            name: "leads-list",
            component: LeadListView,
            meta: { isGranted: (u: SalesUserStore) => u.isAgent },
        },
        {
            path: "/transaktionen",
            name: "transaction-list",
            component: TransactionListView,
            meta: { isGranted: (u: SalesUserStore) => u.isAgent },
        },
        {
            path: "/agenten",
            name: "agent-list",
            component: AgentListView,
            meta: { isGranted: (u: SalesUserStore) => u.isAdmin },
        },
        {
            path: "/preise",
            name: "token-price-list",
            component: TokenPriceListView,
            meta: { isGranted: (u: SalesUserStore) => u.isAdmin },
        },
        {
            path: "/bonus-kampagne",
            name: "bonus-campaign-list",
            component: BonusCampaignsView,
            meta: { isGranted: (u: SalesUserStore) => u.isAdmin },
        },
    ],
})

router.beforeEach(async to => {
    const title = to.meta.title

    if (to.meta.isGranted) {
        const isGranted = to.meta.isGranted as (u: SalesUserStore) => boolean
        const userStore = useSalesUserStore()
        await userStore.ensureLoaded()

        if (!userStore.authenticated) {
            console.error("[Router]: Not logged in")
            return { name: "login" }
        }

        if (!isGranted(userStore)) {
            console.error("[Router]: Not authorized")
            return { name: "dashboard" }
        }
    }

    if (title) {
        document.title = `${title} • Scoopas Sales`
    } else {
        document.title = "Scoopas Sales"
    }

    return true
})

export default router

<script setup lang="ts">
import type { Agent } from "@/model/app/agent"
import { ScInlineList, ScShowToolbar } from "@/sales/components/crud"
import ScShow from "@/sales/components/crud/ScShow.vue"
import SendTemporaryPasswordConfirm from "@/sales/components/SendTemporaryPasswordConfirm.vue"
import ScTab from "@/sales/components/tabs/ScTab.vue"
import ScTabGroup from "@/sales/components/tabs/ScTabGroup.vue"
import { normalizeModuleConfiguration, useLoadEntity } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { ref } from "vue"
import { createModuleConfiguration } from "./agents.config"

const props = defineProps<{ id: string }>()
const config = createModuleConfiguration()
const { item } = await useLoadEntity<Agent>(props.id, config)

const showSendTemporaryPassword = ref(false)

const customerConfig = normalizeModuleConfiguration({
    apiBase: () => `/sales/agent/${props.id}/customer`,
    list: {
        id: () => "agent-customer-list",
        defaultItemsPerPage: 5,
        fields: [
            {
                name: "lastName",
                type: "string",
                title: "Nachname",
            },
            {
                name: "firstName",
                type: "string",
                title: "Vorname",
            },
            {
                name: "email",
                type: "string",
                title: "E-Mail",
            },
            {
                name: "createdAt",
                type: "datetime",
                title: "Seit",
            },
        ],
        trClass: () => "",
        onClick: null,
        buttonColumn: false,
    },
})
</script>

<template>
    <ScShow v-bind="{ config, item }">
        <template #default>
            <div class="card-title">
                {{ item.firstName }}
                {{ item.lastName }}
            </div>

            <div class="mt-5">
                <ScTabGroup>
                    <ScTab title="Kunden">
                        <ScInlineList :config="customerConfig" />
                    </ScTab>
                </ScTabGroup>
            </div>
        </template>
        <template #toolbar>
            <div class="flex">
                <ScShowToolbar :item="item" />
                <OutlineButton class="ml-1" @click="showSendTemporaryPassword = !showSendTemporaryPassword">
                    Ein-Mal-Passwort senden
                </OutlineButton>
            </div>

            <SendTemporaryPasswordConfirm
                :url="`/sales/agent/${props.id}/send-temporary-password`"
                :show="showSendTemporaryPassword"
                @close="showSendTemporaryPassword = false"
            >
                Das Passwort des Agenten wird geändert und eine E-Mail mit den neuen Zugangsdaten versendet. Der Agent
                wird bei seinem nächsten Login aufgefordert, sein Passwort zu ändern.
            </SendTemporaryPasswordConfirm>
        </template>
    </ScShow>
</template>

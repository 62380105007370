<script setup lang="ts">
import { ScEdit } from "@/sales/components/crud"
import { createModuleConfiguration } from "./token-prices.config"

const config = createModuleConfiguration()
</script>

<template>
    <ScEdit v-bind="{ config }"></ScEdit>
</template>

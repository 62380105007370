import type { TokenPrice } from "@/model/app/token-price"
import { $currency, normalizeModuleConfiguration } from "@/vf"
import TokenPriceCreateView from "./TokenPriceCreateView.vue"

export function createModuleConfiguration() {
    return normalizeModuleConfiguration({
        apiBase: () => "/sales/token-price",
        mode: "in-table",
        list: {
            orderField: "validFrom",
            orderDirection: "desc",
            onClick: null,
            fields: [
                {
                    name: "euroCentsPerToken",
                    title: "Preis pro Token",
                    type: "string",
                    getter: (item: TokenPrice) => $currency(item.euroCentsPerToken ?? 0),
                },
                {
                    name: "validFrom",
                    title: "Gültig ab",
                    type: "datetime",
                    sortField: "a.validFrom",
                },
            ],
        },
        new: TokenPriceCreateView,
    })
}

<script lang="ts" setup>
import { injectMandatory, type Entity, type ModuleConfiguration } from "@/vf"
import { ScDeleteButton, ScEditButton } from "."

defineProps<{
    item: Entity
}>()
const config = injectMandatory<ModuleConfiguration>("module-config", "VfShowToolbar must be used inside VfEdit")
</script>

<template>
    <div>
        <ScEditButton v-if="config.show.editButton(item)"></ScEditButton>
        <ScDeleteButton v-if="config.show.deleteButton(item)"></ScDeleteButton>
    </div>
</template>

<script lang="ts" setup>
import { deepCopy } from "@/vf"
import { findDataForName, findSchemaForName } from "@/vf/utils/SymfonyForm"
import { FormKitSchema } from "@formkit/vue"
import type { Ref } from "vue"
import { computed, nextTick, ref, watch } from "vue"
import VfFormRendered from "./VfFormRendered.vue"

const props = defineProps<{
    name?: string // if no name is supplied, a schema is *required*
    schema?: any // if no schema is supplied, a name is *required*
    path?: string // path in the schema if the form row is inside a FormKit element
    isRest?: boolean
    onlyMarkRendered?: boolean
    sectionsSchema?: any
    data?: any
    disabled?: boolean
}>()

const emit = defineEmits<{
    (
        e: "filterOptions",
        data: Ref,
        options: { key: string; value: string; additionalData?: object }[],
        updateOptions: (options: { key: string; value: string; additionalData?: object }[]) => void,
    ): { key: string; value: string }
}>()

// let label: string | null = null

// we *want* to lose reactivity here
// eslint-disable-next-line vue/no-setup-props-destructure
let schema = props.schema
// eslint-disable-next-line vue/no-setup-props-destructure
let data = props.data
if (!schema) {
    if (!props.name) {
        throw new Error("If no schema is provided to VfFormRow, a name is required.")
    }

    schema = deepCopy(findSchemaForName(props.name, "VfFormRow", props.path))
    data = findDataForName(props.name, "VfFormRow")
}
const showFormkit = ref(true)
let isFirstRender = true
if (schema.options) {
    const options = schema.options
    const varName = "$" + schema.name + "Options"
    schema.options = "$" + varName
    data.value[varName] = computed(() => {
        let out = options
        emit("filterOptions", data, options, newOptions => {
            out = newOptions
            if (!isFirstRender) {
                showFormkit.value = false
            }
            isFirstRender = false
            if (out.length > 0 || schema.$formkit !== "checkbox") {
                nextTick(() => {
                    showFormkit.value = true
                })
            }
        })
        return out
    })
}

if (props.sectionsSchema) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    schema.sectionsSchema = props.sectionsSchema
}

watch(
    () => [props.disabled, props.name, props.schema],
    () => {
        schema.disabled = props.disabled
    },
)
</script>

<template>
    <FormKitSchema v-if="!props.onlyMarkRendered && showFormkit" :schema="schema" :data="data" :disabled="disabled" />
    <VfFormRendered v-if="!props.isRest" :name="props.name ?? schema.name"></VfFormRendered>
</template>

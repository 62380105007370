import type {
    ListFieldConfiguration,
    ListState,
    ListStateHooks,
    ModuleConfiguration,
    NormalizedListFieldGroupConfiguration,
} from "@/vf"
import { $bool, $currency, $date, $datetime, $shortdatetime, createSignaler } from "@/vf"
import { computed, reactive, type Ref } from "vue"

export function computeThClass(
    field: ListFieldConfiguration,
    group: NormalizedListFieldGroupConfiguration,
    indexInGroup: number,
    groupIndexInTable: number,
    listState: ListState,
) {
    return computed(() => {
        const classes = []

        if (indexInGroup === 0 && groupIndexInTable === 0) {
            classes.push("vf-first-in-table")
        }

        if (field.sortField !== undefined) {
            classes.push("vf-sortable")
        }

        if (field.name === listState.orderField) {
            classes.push("vf-th-sort-active")
        }

        if (indexInGroup === 0) {
            classes.push("vf-first-in-group")
        }

        if (indexInGroup === group.fields.length - 1) {
            classes.push("vf-last-in-group")
        }

        if (field.thClass) {
            classes.push(field.thClass())
        }

        return classes.join(" ")
    })
}

export function sortListByField(listState: ListState, field: ListFieldConfiguration) {
    if (field.sortField === undefined) {
        return
    }

    if (listState.orderField === field.name) {
        listState.orderDirection = listState.orderDirection === "asc" ? "desc" : "asc"
    } else {
        listState.orderField = field.name
        listState.orderDirection = "desc"
    }
}

/**
 * Get the fields value (either by getter or by field name)
 */
export function readField(item: any, field: ListFieldConfiguration) {
    if (field.getter) {
        return field.getter(item)
    }

    const propertyPath = field.name.split(".")
    let value = item

    for (const segment of propertyPath) {
        value = value?.[segment]
    }

    return value
}

export function renderField(item: any, field: ListFieldConfiguration) {
    const value = readField(item, field)

    switch (field.type) {
        case "string":
        case "integer":
            return value

        case "currency":
            return $currency(value)

        case "datetime":
            return $datetime(value)

        case "datetimeshort":
            return $shortdatetime(value)

        case "date":
            return $date(value)

        case "bool":
            return $bool(value)

        case "html":
        case "vnode":
        case "multi-row":
            throw new Error(`${field.type} can not be rendered from renderField()`)
    }
}

export function createListState(config: Ref<ModuleConfiguration>) {
    const signaler = createSignaler<ListStateHooks>()

    const listState: ListState = reactive({
        orderField: config.value.list.orderField,
        orderDirection: config.value.list.orderDirection,
        page: 1,
        filter: config.value.list.defaultFilter(),
        searchValue: "",
        itemsPerPage: config.value.list.defaultItemsPerPage,
        totalItems: -1,
        pageCount: 0,
        list: [],
        pagination: [],
        customizeFields: {},
        loading: false,
        refresh: signaler.refresh,
        columnClicked: signaler.columnClicked,
        deleteEntity: signaler.deleteEntity,
        editEntity: signaler.editEntity,
        resetCustomizeFields: signaler.resetCustomizeFields,
        hook: signaler.hook,
    })

    return listState
}

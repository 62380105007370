<template>
    <div class="feedback-icon feedback-error feedback-animate-error-icon">
        <span class="feedback-x-mark">
            <span class="feedback-x-mark-line-left"></span>
            <span class="feedback-x-mark-line-right"></span>
        </span>
    </div>
</template>

<style scoped>
.feedback-icon.feedback-error {
    color: #d92550;
    display: flex;
    margin: 0 auto;

    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 2.5em auto;
    border: 0.25em solid #d92550;
    border-radius: 50%;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    user-select: none;
}

.feedback-icon.feedback-error .feedback-x-mark {
    position: relative;
    flex-grow: 1;
}

.feedback-icon.feedback-error [class^="feedback-x-mark-line"] {
    display: block;
    position: absolute;
    top: 2.3125em;
    width: 2.9375em;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #d92550;
}

.feedback-icon.feedback-error [class^="feedback-x-mark-line"][class$="left"] {
    left: 1.0625em;
    transform: rotate(45deg);
}

.feedback-icon.feedback-error [class^="feedback-x-mark-line"][class$="right"] {
    right: 1em;
    transform: rotate(-45deg);
}

.feedback-animate-error-icon {
    animation: feedback-animate-error-icon 0.5s;
}

.feedback-animate-error-icon .feedback-x-mark {
    animation: feedback-animate-error-x-mark 0.5s;
}

@keyframes feedback-animate-error-icon {
    0% {
        transform: rotateX(100deg);
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        opacity: 1;
    }
}

@keyframes feedback-animate-error-x-mark {
    0% {
        margin-top: 1.625em;
        transform: scale(0.4);
        opacity: 0;
    }
    50% {
        margin-top: 1.625em;
        transform: scale(0.4);
        opacity: 0;
    }
    80% {
        margin-top: -0.375em;
        transform: scale(1.15);
    }
    100% {
        margin-top: 0;
        transform: scale(1);
        opacity: 1;
    }
}
</style>

<script lang="ts" setup>
import type { ListState } from "@/vf"
import { injectMandatory } from "@/vf"
import { computed, toRefs } from "vue"

const listState = injectMandatory<ListState>(
    "list-state",
    "VfListPagination must be used inside an element that provides list-state like VfList.",
)
const { page, itemsPerPage, pagination, pageCount, totalItems } = toRefs(listState)

// The number of pages that should be shown in the pagination BEFORE and AFTER the current page.
const pageRange = 2

const pages = computed<number[]>(() => {
    let pages: number[] = []
    let start = page.value - pageRange
    let end = page.value + pageRange

    if (start <= 0) {
        // less than <pageRange> pages before the current page, extend the number of pages after the current page
        end += -start + 1
        start = 1
    }

    if (end >= pageCount.value) {
        // more pages after page than maximum page number, cut off the rest and append to the beginning
        start -= end - pageCount.value
        end = pageCount.value
    }

    // check the result and adjust it if something is out of bounds
    if (start <= 0) {
        start = 1
    }

    if (end >= pageCount.value) {
        end = pageCount.value
    }

    // build the pagination array that we can iterate in the template
    for (let i = start; i <= end; ++i) {
        pages.push(i)
    }

    return pages
})

function changePage(newPageNumber: number) {
    listState.page = newPageNumber
    listState.refresh()
}

function changeItemsPerPage(newItemsPerPage: number) {
    listState.itemsPerPage = newItemsPerPage
    listState.refresh()
}
</script>

<template>
    <slot
        v-bind="{ page, itemsPerPage, pagination, pageCount, totalItems, changePage, changeItemsPerPage, pages }"
    ></slot>
</template>

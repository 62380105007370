<script setup lang="ts">
import { ref } from "vue"
import LeadEnterNote from "./LeadEnterNote.vue"
import LeadNoteList from "./LeadNoteList.vue"

defineProps<{ leadId: string }>()

const noteList = ref<typeof LeadNoteList>()
</script>

<template>
    <LeadEnterNote :lead-id="leadId" class="pb-5" @created="noteList?.refresh" />
    <LeadNoteList :lead-id="leadId" ref="noteList" />
</template>

<script setup lang="ts">
import { $currency, $smt, isNumericKey } from "@/common/util/format"
import type { Transaction } from "@/model/app/transaction"
import ScShow from "@/sales/components/crud/ScShow.vue"
import { useSalesUserStore } from "@/sales/stores/sales-user-store"
import { $datetime, AnimateIf, useHttpClient, useInlineTable, useLoadEntity } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { AxiosError } from "axios"
import { DateTime } from "luxon"
import { computed, ref } from "vue"
import { createModuleConfiguration } from "./transactions.config"

const props = defineProps<{ id: string }>()
const config = createModuleConfiguration()
const { item, refresh } = await useLoadEntity<Transaction>(props.id, config)
const { post } = useHttpClient()
const { isAdmin } = useSalesUserStore()
const inlineTable = useInlineTable()
const showPartialBooking = ref(false)
const partialBookingEuroAmount = ref("")

async function bookTransaction() {
    await post(`/sales/transaction/${item.value.id}/book`)
    await refresh()
    inlineTable?.emit("refresh")
}
async function submitPartialBooking() {
    const euroAmount = parseFloat(partialBookingEuroAmount.value)
    const euroCentAmount = Math.round(euroAmount * 100)
    try {
        await post(`/sales/transaction/${item.value.id}/partial-book`, { euroCentAmount })
        showPartialBooking.value = false
    } catch (e) {
        if (e instanceof AxiosError && e.response?.status == 400) {
            alert(
                "Ungültiger Betrag, maximal " +
                    $currency(item.value.euroCentAmount! - item.value.euroCentAmountPaid!) +
                    " EUR möglich ",
            )
        }
        console.error(e)
    }
    await refresh()
    inlineTable?.emit("refresh")
}

/*
 * Change valid until
 */
const showChangeValidUntil = ref(false)
const newValidUntil = ref(
    item.value.validUntil ? DateTime.fromISO(item.value.validUntil).toFormat("yyyy-MM-dd") : undefined,
)
const newValidUntilInPast = computed<boolean>(() => {
    if (newValidUntil.value === undefined) {
        return false
    }
    return DateTime.fromISO(newValidUntil.value).set({ hour: 23 }) < DateTime.local().set({ hour: 0 })
})

async function submitChangeValidUntil() {
    await post(`/sales/transaction/${item.value.id}/change-valid-until`, { validUntil: newValidUntil.value })
    await refresh()
    showChangeValidUntil.value = false
}
</script>

<template>
    <ScShow v-bind="{ config, item }" :toolbar="false">
        <div class="card-title !mb-0">
            <b>Transaktion</b>
            #{{ item.id }}
        </div>
        <div class="italic opacity-50">Kunde {{ item.customer?.fullName }}</div>

        <div v-if="item.notice" class="mt-10">
            <b>Notiz:</b>
            <div>{{ item.notice }}</div>
        </div>

        <div class="grid grid-cols-4 mt-10 gap-10">
            <div class="col-span-2">
                <div class="flex justify-between items-center">
                    <div>
                        <div class="text-4xl font-bold text-primary">{{ $smt(item.totalTokenAmount!) }} SMT</div>
                        <div class="text-2xl font-bold">inkl. {{ $smt(item.bonusToken!) }} SMT Bonus</div>
                    </div>
                    <div class="text-right">
                        <div class="text-2xl font-bold text-primary">{{ $currency(item.euroCentAmount!) }} EUR</div>
                        <div class="italic opacity-50">{{ $currency(item.euroCentsPerToken!) }} EUR / SMT</div>
                    </div>
                </div>

                <div class="text-xl mt-5">
                    <span v-if="item.state === 'booked'">
                        vollständig gebucht am {{ $datetime(item.bookedAt) }}
                        <span v-if="item.bookedBy">
                            durch {{ item.bookedBy?.firstName }} {{ item.bookedBy?.lastName }} ({{
                                item.bookedBy?.agentCode
                            }})
                        </span>
                    </span>
                    <span v-if="item.state === 'pending'">reserviert bis {{ $datetime(item.validUntil) }}</span>
                    <div v-if="item.state === 'partial'">
                        Teilgebucht.
                        <br />
                        Bereits bezahlt: {{ $currency(item.euroCentAmountPaid!) }} EUR ({{
                            $smt(item.tokenAmountBooked!)
                        }}
                        SMT)
                        <br />
                        Noch offen: {{ $currency(item.euroCentAmount! - item.euroCentAmountPaid!) }} EUR
                    </div>
                </div>
            </div>
            <div>
                <b>Adresse</b>
                <div>{{ item.customer?.fullName }}</div>
                <div>{{ item.customer?.street }}</div>
                <div>{{ item.customer?.zipCode }} {{ item.customer?.city }}</div>
            </div>

            <div>
                <b>Telefon</b>
                <div class="mb-2">
                    <a class="link" :href="`tel:${item.customer?.phone}`">{{ item.customer?.phone }}</a>
                </div>

                <b>E-Mail</b>
                <div>
                    <a class="link" :href="`mailto:${item.customer?.email}`">{{ item.customer?.email }}</a>
                </div>
            </div>
        </div>
        <div class="mt-10" v-if="item.state === 'pending' || item.state === 'partial'">
            <FilledButton @click="bookTransaction" v-if="isAdmin && item.state === 'pending'">
                Vollständig Buchen
            </FilledButton>
            <FilledButton @click="showPartialBooking = !showPartialBooking" v-if="isAdmin">Teilbuchen</FilledButton>
            <OutlineButton @click="showChangeValidUntil = !showChangeValidUntil">Verlängern</OutlineButton>
        </div>
        <AnimateIf :if="isAdmin && showPartialBooking">
            <form @submit.prevent="submitPartialBooking" class="bg-secondary p-5 rounded-xl shadow-lg mb-5">
                <div class="mb-5 text-white">
                    <b>Wie viele Euro soll der Transaktion aufgebucht werden?</b>
                </div>

                <input
                    type="number"
                    class="input-field"
                    v-model.number="partialBookingEuroAmount"
                    step="0.01"
                    placeholder="Menge in Euro"
                    @keypress="isNumericKey"
                />
                <FilledButton type="submit">Teilbuchen</FilledButton>
                <OutlineButton @click="showPartialBooking = false">Abbrechen</OutlineButton>
            </form>
        </AnimateIf>
        <AnimateIf :if="showChangeValidUntil">
            <form @submit.prevent="submitChangeValidUntil" class="bg-secondary p-5 rounded-xl shadow-lg mb-5 mt-3">
                <div class="mb-5 text-white">
                    <b>Bis wann soll diese Reservierung gültig sein?</b>
                </div>

                <input type="date" class="input-field" v-model="newValidUntil" />

                <div v-if="newValidUntilInPast" class="text-red-600 mb-4 animate-pulse">
                    Achtung: Das neue Datum liegt in der Vergangenheit. Die Transaktion wird damit sofort ungültig.
                </div>

                <FilledButton type="submit">Speichern</FilledButton>
                <OutlineButton @click="showChangeValidUntil = false">Abbrechen</OutlineButton>
            </form>
        </AnimateIf>
        <div v-if="item.payments?.length" class="mt-10">
            <table class="table">
                <tr>
                    <th>Zahlungen</th>
                    <th class="text-right">EUR</th>
                    <th class="text-right">SMT</th>
                    <th class="text-right">Gebucht von</th>
                </tr>
                <tr v-for="payment in item.payments" :key="payment.id!">
                    <td>{{ $datetime(payment.bookedAt) }}</td>
                    <td class="text-right">{{ $currency(payment.euroCentAmount!) }} EUR</td>
                    <td class="text-right">{{ $smt(payment.tokenAmount!) }} SMT</td>
                    <td class="text-right">
                        {{ payment.bookedBy?.firstName }} {{ payment.bookedBy?.lastName }} ({{
                            payment.bookedBy?.agentCode
                        }})
                    </td>
                </tr>
            </table>
        </div>
    </ScShow>
</template>

<script lang="ts" setup>
import {
    injectMandatory,
    VfBackButton,
    VfDeleteButton,
    VfSaveButton,
    type Entity,
    type ModuleConfiguration,
} from "@/vf"
import { computed, ref } from "vue"

const props = defineProps<{
    item: Entity
}>()
const config = injectMandatory<ModuleConfiguration>("module-config", "VfEditToolbar must be used inside VfEdit")

const mode = computed(() => config.show.page(props.item).mode)
const deleteConfirmBox = ref<HTMLElement | undefined>(undefined)
</script>

<template>
    <div>
        <div class="d-flex justify-content-between">
            <VfBackButton v-if="config.edit.backButton() && mode !== 'router'"></VfBackButton>
            <VfDeleteButton
                v-if="config.edit.deleteButton(item)"
                :confirm-teleport-target="deleteConfirmBox"
            ></VfDeleteButton>
            <VfSaveButton></VfSaveButton>
        </div>
        <div ref="deleteConfirmBox"></div>
    </div>
</template>

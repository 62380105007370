<script setup lang="ts">
import { ScInlineList } from "@/sales/components/crud"
import { normalizeModuleConfiguration } from "@/vf"
import { ref } from "vue"

const props = defineProps<{
    leadId: string
}>()

const config = normalizeModuleConfiguration({
    apiBase: () => `/sales/lead/${props.leadId}/agent-history`,
    list: {
        fields: [
            {
                name: "agentCode",
                type: "string",
                title: "Agent",
                sortField: "agent.agentCode",
            },
            {
                name: "createdAt",
                type: "datetime",
                title: "Seit",
                sortField: "a.createdAt",
            },
        ],
        orderDirection: "desc",
        orderField: "createdAt",
        buttonColumn: false,
    },
})

const list = ref<typeof ScInlineList>()

defineExpose({
    refresh: () => list.value?.refresh(),
})
</script>

<template>
    <div>
        <ScInlineList :config="config" ref="list" />
    </div>
</template>

<script setup lang="ts">
import {
    computeThClass,
    sortListByField,
    useInlineTable,
    useModuleAction,
    VfListCompose,
    VfListRow,
    type ListFieldConfiguration,
    type ListState,
    type ModuleConfiguration,
    type NormalizedListFieldGroupConfiguration,
} from "@/vf"
import Fa6SolidSortDown from "~icons/fa6-solid/sort-down"
import Fa6SolidSortUp from "~icons/fa6-solid/sort-up"

defineProps<{
    config: ModuleConfiguration
    listState: ListState
    fields: ListFieldConfiguration[]
    filteredFieldGroups: NormalizedListFieldGroupConfiguration[]
}>()

const moduleAction = useModuleAction()
const inlineTable = useInlineTable(false)
</script>

<template>
    <table class="table vf-crud-list" :class="{ 'table-row-tabs': config.list.showOpenRowAsTab }">
        <thead>
            <VfListCompose
                :config="config"
                :inline-table="inlineTable"
                :colspan="fields.length + (config.list.buttonColumn() || $slots.listButtons ? 1 : 0)"
                row-id="new"
            ></VfListCompose>
            <slot name="additionalHeaderRow"></slot>
            <template v-if="config.list.showFieldGroupsHeadRow">
                <tr class="vf-field-group-header">
                    <template v-for="(fieldGroup, groupIndex) of filteredFieldGroups" :key="fieldGroup.name">
                        <th
                            :colspan="fieldGroup.fields.length"
                            class="vf-first-in-group vf-last-in-group"
                            :class="{ 'vf-first-in-table': groupIndex === 0 }"
                        >
                            {{ fieldGroup.title }}
                        </th>
                    </template>
                </tr>
            </template>
            <tr>
                <template v-for="(fieldGroup, groupIndex) of filteredFieldGroups">
                    <template v-for="(field, fieldIndex) of fieldGroup.fields" :key="field.name">
                        <th
                            :class="computeThClass(field, fieldGroup, fieldIndex, groupIndex, listState).value"
                            @click="sortListByField(listState, field)"
                        >
                            <span>{{ field.title }}</span>

                            <div class="float-right grid">
                                <Transition
                                    enter-active-class="transition"
                                    enter-from-class="scale-0"
                                    enter-to-class="scale-1"
                                    leave-active-class="transition"
                                    leave-from-class="scale-1"
                                    leave-to-class="scale-0"
                                >
                                    <Fa6SolidSortUp
                                        class="grid-one"
                                        v-if="listState.orderField === field.name && listState.orderDirection === 'asc'"
                                    />
                                    <Fa6SolidSortDown
                                        class="grid-one"
                                        v-else-if="
                                            listState.orderField === field.name && listState.orderDirection === 'desc'
                                        "
                                    />
                                </Transition>
                            </div>
                        </th>
                    </template>
                </template>
                <th v-if="config.list.buttonColumn() || $slots.listButtons" class="border-left-0 text-right">
                    Aktionen
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-if="listState.loading">
                <template v-for="i of 3" :key="'loading' + i">
                    <tr>
                        <template v-for="fieldGroup of filteredFieldGroups">
                            <template v-for="field of fieldGroup.fields" :key="'loading' + i + field.name">
                                <td class="animate-pulse">
                                    <div class="h-6 w-50 rounded bg-secondary opacity-10"></div>
                                </td>
                            </template>
                        </template>
                    </tr>
                </template>
            </template>
            <template v-else>
                <template v-for="item in listState.list" :key="item.id">
                    <VfListRow v-bind="{ config, listState, item, moduleAction, filteredFieldGroups }">
                        <!-- slot pass-through-->
                        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
                            <slot :name="name" v-bind="slotData" />
                        </template>
                    </VfListRow>
                    <VfListCompose
                        :config="config"
                        :inline-table="inlineTable"
                        :colspan="fields.length + (config.list.buttonColumn() || $slots.listButtons ? 1 : 0)"
                        :filtered-field-groups="filteredFieldGroups"
                        :row-id="item.id"
                    ></VfListCompose>
                </template>
            </template>
        </tbody>
    </table>
</template>

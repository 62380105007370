<script lang="ts" setup>
import { injectMandatory, useHttpClient, useModuleAction, type Entity, type ModuleConfiguration } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { onClickOutside } from "@vueuse/core"
import { ref } from "vue"

const config = injectMandatory<ModuleConfiguration>("module-config", "ScDeleteButton requires a config to be injected")
const item = injectMandatory<Entity>("item", "ScDeleteButton requires an item to be injected")
const moduleAction = useModuleAction()
const showConfirmation = ref(false)
const confirmationBox = ref<HTMLDivElement>()

onClickOutside(confirmationBox.value, () => {
    showConfirmation.value = false
})

async function performDelete() {
    await useHttpClient().delete(config.entityPath(item))
    moduleAction.executeAfterAction(config.afterDeleteAction, item, false, true)
}
</script>

<template>
    <div class="inline mx-3">
        <OutlineButton color="primary" @click="showConfirmation = !showConfirmation" v-if="item?.id" class="z-10">
            Löschen
        </OutlineButton>

        <div class="inline relative" ref="confirmationBox">
            <div class="absolute">
                <Transition
                    enter-active-class="transition"
                    enter-from-class="-translate-x-20 opacity-0"
                    enter-to-class="translate-x-0 opacity-100"
                    leave-active-class="transition"
                    leave-from-class="translate-x-0 opacity-100"
                    leave-to-class="-translate-x-20 opacity-0"
                >
                    <div
                        v-if="showConfirmation"
                        class="inline-block z-0 p-5 border border-red-800 rounded-xl bg-white shadow-2xl ml-3 -translate-y-full"
                    >
                        <div class="text-center mb-3">Wirklich löschen?</div>

                        <div class="flex">
                            <FilledButton color="danger" @click="performDelete">Bestätigen</FilledButton>
                            <OutlineButton color="primary" @click="showConfirmation = false">Abbrechen</OutlineButton>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

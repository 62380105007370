<script setup lang="ts">
import { inject } from "vue"

const props = defineProps<{
    title: string
    id?: string
}>()

const activeTab = inject("ScTabGroup:activeTab")
</script>

<template>
    <div class="p-5" v-show="activeTab === (props.id ?? props.title)">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = withDefaults(
    defineProps<{
        bigger?: boolean
        color?: "primary" | "white" | "secondary"
        type?: "button" | "submit" | "reset"
        disabled?: boolean
    }>(),
    {
        color: "primary",
        type: "button",
        disabled: false,
    },
)

const classes = computed(() => {
    const classes = [
        "inline-block",
        "rounded",
        "border",
        "transition",
        "duration-300",
        "hover:shadow-lg",
        "font-bold",
        "disabled:opacity-50",
        "disabled:hover:shadow-none",
    ]

    switch (props.color) {
        // primary = primary on white bg
        case "primary":
            classes.push(
                "border-primary",
                "text-primary",
                "hover:bg-primary",
                "hover:text-white",
                "disabled:hover:bg-transparent",
                "disabled:hover:text-primary",
            )
            break

        // secondary = secondary on white bg
        case "secondary":
            classes.push("border-secondary-tint", "text-secondary", "hover:bg-secondary", "hover:textwhite")
            break

        // white = white on primary bg
        case "white":
            classes.push("border-white", "text-white", "hover:bg-white", "hover:text-primary")
            break
    }

    classes.push(props.bigger ? "py-4 px-8" : "py-2 px-8")

    return classes
})
</script>

<template>
    <button :class="classes" :type="type" :disabled="disabled">
        <slot />
    </button>
</template>

<template>
    <div class="text-primary feedback-question">
        <i class="fa fa-question-circle fa-5x"></i>
    </div>
</template>

<style scoped>
.feedback-question {
    text-align: center;

    /* to match other feedback elements: */
    height: 5em;
    width: 5em;
    margin: 1.25em auto 1.875em;
    border: 0.25em solid transparent;
    box-sizing: content-box;
    transform: translate3d(-3px, -3px, 0);
}
</style>

<script setup lang="ts">
import { generateRandomString, type InlineTable, type LoadedComponent, type ModuleConfiguration } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import { ref, watch } from "vue"

const props = withDefaults(
    defineProps<{
        config?: ModuleConfiguration
        inlineTable: InlineTable
        rowId: string
    }>(),
    {
        colspan: 1,
        config: undefined,
        filteredFieldGroups: undefined,
    },
)

const loadedComponents = ref<(LoadedComponent & { key: string; if: boolean })[]>([])

watch(props.inlineTable.loadedComponent, update, {
    immediate: true,
})

watch(() => props.rowId, update)

function update() {
    for (const loadedComponent of loadedComponents.value) {
        loadedComponent.if = false
    }
    if (props.inlineTable.loadedComponent.value?.rowId === props.rowId) {
        // load new component
        loadedComponents.value.push({
            ...props.inlineTable.loadedComponent.value,
            key: (props.inlineTable.loadedComponent.value?.rowId ?? "") + generateRandomString(5),
            if: true,
        })
    }
}
</script>

<template>
    <AnimateIf
        v-for="(loadedComponent, i) in loadedComponents"
        :key="loadedComponent.key"
        :if="loadedComponent.if"
        :animate-on-init="true"
        @unmounted="loadedComponents.splice(i, 1)"
        @opened="loadedComponent.promiseResolve(undefined)"
        @closed="loadedComponent.promiseClose()"
    >
        <slot v-bind="{ loadedComponent }"></slot>
    </AnimateIf>
</template>

import { $smt } from "@/common/util/format"
import type { Customer } from "@/model/app/customer"
import CustomerEditView from "@/sales/views/customer/CustomerEditView.vue"
import CustomerShowView from "@/sales/views/customer/CustomerShowView.vue"
import { normalizeModuleConfiguration, type ModuleConfigurationOptions } from "@/vf"
import { computed, type Ref } from "vue"

export function createModuleConfiguration(showOnlyMyLeads: Ref<boolean> | undefined = undefined) {
    const config: ModuleConfigurationOptions = {
        apiBase: () => "/sales/customer",
        mode: "in-table",
        list: {
            fieldsCustomizable: true,
            newButton: false,
            requestParams: computed(() => ({ showOnlyMyLeads: showOnlyMyLeads?.value ? "1" : "0" })),
            fields: [
                { name: "number", title: "Nr.", type: "string" },
                { name: "lastName", title: "Nachname", type: "string", sortField: "lead.lastName" },
                { name: "firstName", title: "Vorname", type: "string", sortField: "lead.firstName" },
                { name: "company", title: "Firma", type: "string", defaultHidden: true, sortField: "lead.company" },
                { name: "email", title: "E-Mail", type: "string" },
                { name: "phone", title: "Telefon", type: "string" },
                {
                    name: "balance",
                    title: "Saldo",
                    type: "string",
                    getter: (item: Customer) => $smt(item.tokenBalance ?? "0") + " SMT",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    sortField: "wallet.tokenBalance",
                },
                {
                    name: "reserved",
                    title: "Reserviert",
                    type: "string",
                    getter: (item: Customer) => $smt(item.tokensReserved ?? "0") + " SMT",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    sortField: "wallet.tokensReserved",
                },
                { name: "agentCode", title: "Agent", type: "string", sortField: "agent.agentCode" },
                { name: "createdAt", title: "Registriert am", type: "date", sortField: "a.createdAt" },
                { name: "birthdate", title: "Geburtstag", type: "date", sortField: "lead.birthdate" },
            ],
        },
        show: CustomerShowView,
        edit: CustomerEditView,
    }

    return normalizeModuleConfiguration(config)
}

import type { Entity, ModuleConfiguration } from "@/vf"
import { useHttpClient } from "@/vf"
import type { Ref } from "vue"
import { computed, isRef, ref, unref, watch } from "vue"

export async function useLoadEntity<T = Entity>(
    id: string | number | undefined,
    config: ModuleConfiguration | Ref<ModuleConfiguration>,
    createEmptyEntity?: () => any,
): Promise<{
    item: Ref<T>
    refresh: () => Promise<void>
    isNew: Ref<boolean>
}> {
    let load = createEmptyEntity ?? (async () => ({}))

    if (id) {
        const http = useHttpClient()
        load = async () => (await http.get<T>(unref(config).entityPath(id))).data
    }

    const item = ref<any>(await load())

    const refresh = async () => {
        item.value = await load()
    }

    if (isRef(config)) {
        watch(
            () => config,
            () => refresh(),
        )
    }

    return {
        item,
        refresh,
        isNew: computed(() => !id),
    }
}

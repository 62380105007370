<script setup lang="ts">
import { sleep, useHttpClient } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import OverlappingElement from "@/wallet/components/OverlappingElement.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import { ref } from "vue"

const username = ref("")
const state = ref<"input" | "success" | "fail">("input")

const { post } = useHttpClient()

async function submit() {
    try {
        await post("/sales/user/lost-password", { username })
        state.value = "success"
    } catch {
        state.value = "fail"
        await sleep(500)
        state.value = "input"
    }
}
</script>

<template>
    <Transition :duration="500" appear>
        <OverlappingElement class="w-1/2 mx-auto" v-if="state !== 'success'">
            <template #under><img src="@/wallet/assets/icons/lock.svg" /></template>
            <template #default>
                <form class="" @submit.prevent="submit">
                    <RoundedCard color="modal" :class="{ 'animate-shake': state === 'fail' }">
                        <div class="card-title">
                            <b>Passwort</b>
                            vergessen
                        </div>

                        <div>
                            Bitte geben Sie Ihren Agentur Code ein um Ihr Passwort zurückzusetzen. Sie erhalten
                            anschließend eine E-Mail mit einem Link, über den Sie ein neues Passwort vergeben können.
                        </div>

                        <div class="mt-5 mb-5">
                            <input type="text" v-model="username" placeholder="Agentur Code" class="input-field" />
                        </div>

                        <FilledButton color="primary" class="w-full" type="submit">Zurücksetzen</FilledButton>
                        <RouterLink :to="{ name: 'login' }">
                            <OutlineButton class="w-full mt-2">zurück</OutlineButton>
                        </RouterLink>
                    </RoundedCard>
                </form>
            </template>
        </OverlappingElement>

        <OverlappingElement class="w-1/2 mx-auto" v-else-if="state === 'success'">
            <template #under><img src="@/wallet/assets/icons/lock.svg" /></template>
            <template #default>
                <RoundedCard color="modal">
                    <div class="card-title">
                        <b>Passwort</b>
                        vergessen
                    </div>

                    <p class="mb-5">
                        Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet.
                    </p>

                    <RouterLink :to="{ name: 'login' }">
                        <FilledButton>Zurück</FilledButton>
                    </RouterLink>
                </RoundedCard>
            </template>
        </OverlappingElement>
    </Transition>
</template>

<script setup lang="ts">
import { ScList } from "@/sales/components/crud"
import { createModuleConfiguration } from "./bonus-campaigns.config"

const config = createModuleConfiguration()
</script>

<template>
    <ScList v-bind="{ config }">
        <template #title>Bonus-Kampagnen</template>
    </ScList>
</template>

import {inject, type Ref} from "vue"

// gets provided by VfForm and is used in multiple parts of the crud form system. todo: add a proper type to schema and formModel
export interface VfFormInject {
    schema: any
    formModel: any
    renderedList: Ref<any[]>
}

export function findSchemaForName(name: string, elementName: string, path?: string) {
    const form = inject<VfFormInject | null>("vf-form", null)
    if (!form) {
        throw Error(elementName + ": no VfForm found")
    }
    const nameParts = name.split(".")
    // if (nameParts.length > 1) {
    //     throw Error("We do not support customizing sub-forms at the moment")
    // }
    let schema: any = { children: form.schema.value }
    if (path) {
        // walk down the path without using it later in the generated schema
        for (const namePart of path.split(".")) {
            schema = schema.children.find((i: any) => i.name == namePart)
        }
    }
    for (const namePart of nameParts) {
        schema = schema.children.find((i: any) => i.name == namePart)
    }

    if (!schema) {
        throw Error(elementName + ': no form field named "' + name + '" found')
    }

    let generatedSchema = schema
    for (let i = nameParts.length - 2; i >= 0; i--) {
        generatedSchema = {
            $formkit: "group",
            name: nameParts[i],
            children: [generatedSchema],
        }
    }

    return generatedSchema
}

export function findDataForName(name: string, elementName: string): Ref<any> {
    const form = inject<VfFormInject | null>("vf-form", null)
    if (!form) {
        throw Error(elementName + ": no VfForm found")
    }
    const nameParts = name.split(".")
    nameParts.pop()
    let data = form.formModel
    for (const namePart of nameParts) {
        data = data[namePart]
    }
    return data
}

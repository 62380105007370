import { normalizeModuleConfiguration, type ModuleConfigurationOptions } from "@/vf"

export function createModuleConfiguration(leadId: string) {
    const config: ModuleConfigurationOptions = {
        apiBase: () => `/sales/lead/${leadId}/note`,
        list: {
            buttonColumn: false,
            fieldsCustomizable: true,
            orderField: "date",
            orderDirection: "desc",
            defaultItemsPerPage: 5,
            trClass: () => "align-top",
            fields: [
                { name: "text", title: "Text", type: "string", tdClass: () => "whitespace-pre-line" },
                { name: "date", title: "Datum", type: "datetimeshort", sortField: "a.date" },
                { name: "agentCode", title: "Agent", type: "string" },
            ],
            onClick: null,
        },
    }

    return normalizeModuleConfiguration(config)
}

import type { SalesUserStoreData } from "@/model/app/sales-user-store-data"
import { useEnsureLoaded, useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { reactive, ref, toRefs } from "vue"

export const useSalesUserStore = defineStore("acp-user", () => {
    const authenticated = ref(false)
    const data = reactive<SalesUserStoreData>({
        agentCode: undefined,
        firstName: undefined,
        lastName: undefined,
        isAgent: undefined,
        isModerator: undefined,
        isAdmin: undefined,
        primaryRole: undefined,
    })

    const { get } = useHttpClient()
    const { refresh, ensureLoaded } = useEnsureLoaded("Sales User Store", async () => {
        try {
            const response = await get<SalesUserStoreData>("/sales/user")

            authenticated.value = true

            for (const key of Object.keys(data) as Array<keyof SalesUserStoreData>) {
                // for some reason typescript refuses to accept that the types WILL match...
                // eslint-disable-next-line no-extra-semi
                ;(data as any)[key] = response.data[key]
            }
        } catch {
            clear()
        }
    })

    function clear() {
        authenticated.value = false
        for (const key of Object.keys(data) as (keyof SalesUserStoreData)[]) {
            data[key] = undefined
        }
    }

    return { authenticated, refresh, ensureLoaded, ...toRefs(data), clear }
})

export type SalesUserStore = ReturnType<typeof useSalesUserStore>

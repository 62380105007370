import { inject, markRaw, provide, ref, type Component } from "vue"

export interface LoadedComponent {
    id: string
    rowId: string
    component: Component | undefined
    model: any
    promise: WhenClosedPromise<void> | any // typescript freaks out when only WhenClosedPromise is on this type
    promiseResolve: (arg0: any) => void
    promiseClose: (reason?: CloseReason) => void
    highlightKey: string | null
}

export interface CloseReason {
    saved?: boolean
    deleted?: boolean
    closedByView?: boolean
    closedByRowClick?: boolean
}

declare type InlineTableCallbacks = {
    closed?: (reason: CloseReason, model: any) => any
    [eventName: string]: ((...args: any[]) => any) | undefined
}

class WhenClosedPromise<T> extends Promise<T> {
    private whenClosedFulfilled: ((closeReason?: CloseReason) => void)[] = []

    constructor(
        executor: (
            resolve: (value: T | PromiseLike<T>) => void,
            reject: (reason?: any) => void,
            close: (reason?: CloseReason) => void,
        ) => void,
    ) {
        super((resolve, reject) => {
            executor(resolve, reject, (reason?: CloseReason) => {
                for (const fn of this.whenClosedFulfilled) {
                    fn(reason)
                }
            })
        })
    }

    public whenClosed(onfulfilled: (closeReason?: CloseReason) => void) {
        this.whenClosedFulfilled.push(onfulfilled)
        return this
    }
}

class InlineTable {
    public loadedComponent = ref<LoadedComponent | null>(null)

    constructor(private readonly callbacks: Readonly<InlineTableCallbacks>) {}

    public openNew(inlineView: Component | undefined = undefined) {
        this.open({}, inlineView, "new")
    }

    public open(
        model: { id?: any } | any | null,
        component: Component | undefined,
        rowId: string | null = null,
        highlightKey: string | null = null,
    ): WhenClosedPromise<void> | null {
        const id = model?.id
        if (!rowId) {
            rowId = id
        }
        const rowIsOpen = this.rowIsOpen(rowId!)
        const openRowAfterClose = !rowIsOpen || this.loadedComponent.value?.component != component // test before close to not lose the value of this.loadedComponent
        this.close({ closedByRowClick: true })
        if (openRowAfterClose) {
            let promiseResolve: (arg0: any) => void
            let promiseClose: (arg0: any) => void

            const promise = new WhenClosedPromise<void>((resolve, reject, close) => {
                console.log("[InlineView] loading " + rowId)
                promiseResolve = markRaw(resolve)
                promiseClose = markRaw(close)
            })

            this.loadedComponent.value = {
                id,
                rowId: rowId!,
                component: component ? markRaw(component) : undefined,
                model: model ? markRaw(model) : null,
                highlightKey,
                promise: markRaw(promise),
                promiseResolve: promiseResolve!,
                promiseClose: promiseClose!,
            }

            // if we ever need the opened event, use this:
            // promise.then(() => {
            //     this.emit("opened", newLoadedComponent.model).then()
            // })
            return promise
        }

        return null
    }

    public close(reason?: CloseReason) {
        if (!this.loadedComponent.value) {
            return
        }
        console.log("[InlineView] closing " + this.loadedComponent.value!.rowId)
        this.loadedComponent.value.promise.whenClosed(() => {
            this.emit("closed", reason).then()
        })
        this.loadedComponent.value = null
    }

    public rowIsOpen(rowId: string | undefined | null, component?: Component): boolean {
        if (rowId === undefined || rowId === null) {
            return false
        }
        return (
            this.loadedComponent.value?.rowId == rowId &&
            (!component || this.loadedComponent.value?.component === component)
        )
    }

    public async emit(eventName: string, ...args: any[]) {
        const callback = this.callbacks[eventName]
        if (callback) {
            await callback(...args)
        }
    }
}

export type { WhenClosedPromise,InlineTable }

export function provideInlineTable(inlineTable: InlineTable) {
    provide("inline-table", inlineTable)
}

export function createInlineTable(callbacks: InlineTableCallbacks): InlineTable {
    const inlineTable = new InlineTable({
        closed(reason /*, entity */) {
            if (reason?.saved || reason?.deleted) {
                inlineTable.emit("refresh").then()
            }
        },
        ...callbacks,
    })

    provideInlineTable(inlineTable)
    return inlineTable
}

export function useInlineTable(): InlineTable | null
export function useInlineTable(optional: true): InlineTable | null
export function useInlineTable(optional: false): InlineTable

export function useInlineTable(optional: boolean = true): InlineTable | null {
    const inlineTable = inject("inline-table", null)

    if (!inlineTable) {
        if (optional) {
            return null
        } else {
            throw new Error("`inline-table` inject not defined.")
        }
    }

    return inlineTable
}

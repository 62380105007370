<script setup lang="ts">
import { useChangePassword } from "@/common/composables/useChangePassword"
import { sleep, useHttpClient, VfFeedbackError, VfFeedbackSuccess } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import { computed, ref } from "vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"

const props = withDefaults(
    defineProps<{
        showCancel?: boolean
        url: string
    }>(),
    {
        showCancel: false,
    },
)

defineEmits<{
    (e: "cancel"): void
    (e: "done"): void
}>()
const currentPasswordInput = ref<HTMLInputElement>()
const currentPassword = ref("")
const { postBg } = useHttpClient()

const { newPassword, confirmPassword, passwordsMatch, isValid: isValidBase } = useChangePassword()
const isValid = computed(() => currentPassword.value !== "" && isValidBase.value)

const state = ref<"input" | "loading" | "invalid-password" | "success">("input")

async function submit() {
    state.value = "loading"
    try {
        await postBg(props.url, { currentPassword, newPassword })
        state.value = "success"
    } catch (e) {
        // const response = (e as AxiosError<{ error: string }>).response
        state.value = "invalid-password"
        await sleep(1000)
        state.value = "input"
        currentPassword.value = ""
        currentPasswordInput.value?.focus()
    }
}
</script>

<template>
    <RoundedCard color="white" class="!p-0 overflow-y-hidden" :spinner="state !== 'input'">
        <template #default>
            <form @submit.prevent="submit">
                <slot name="title"></slot>

                <div>
                    Zum Ändern Ihres Passworts geben Sie bitte zu Ihrer Sicherheit Ihr aktuelles Passwort sowie Ihr
                    gewünschtes neues Passwort ein.
                </div>

                <div class="mt-4 mb-8">
                    <input
                        type="password"
                        placeholder="Aktuelles Passwort"
                        class="input-field"
                        autocomplete="current-password"
                        v-model="currentPassword"
                        ref="currentPasswordInput"
                    />
                    <input
                        type="password"
                        placeholder="Neues Passwort"
                        class="input-field"
                        autocomplete="new-password"
                        v-model="newPassword"
                    />
                    <input
                        type="password"
                        placeholder="Neues Passwort wiederholen"
                        class="input-field"
                        autocomplete="new-password"
                        v-model="confirmPassword"
                    />
                    <div
                        class="text-red-600 transition translate-y-5 opacity-0 pl-8"
                        :class="{ '!translate-y-0 opacity-100': !passwordsMatch }"
                    >
                        Passwörter stimmen nicht überein
                    </div>
                </div>

                <div class="mt-10">
                    <FilledButton color="primary" class="px-11" type="submit" :disabled="!isValid">
                        Speichern
                    </FilledButton>
                    <OutlineButton color="primary" class="px-11" @click="$emit('cancel')" v-if="showCancel">
                        Abbrechen
                    </OutlineButton>
                </div>
            </form>
        </template>
        <template #spinner>
            <div class="flex flex-col items-center" v-if="state === 'loading'">
                <GridLoader color="#E4027E" :loading="true"></GridLoader>
                <div class="text-sm mt-5 text-modal">Passwort wird geändert...</div>
            </div>
            <div class="flex flex-col items-center" v-else-if="state === 'invalid-password'">
                <VfFeedbackError />
                <div class="text-sm text-modal">Eingegebenes Passwort ist falsch</div>
            </div>
            <div class="flex flex-col items-center" v-else-if="state === 'success'">
                <VfFeedbackSuccess />
                <div class="text-sm text-modal mb-5">Passwort wurde erfolgreich geändert</div>
                <div>
                    <FilledButton color="primary" @click="$emit('done')">Schließen</FilledButton>
                </div>
            </div>
        </template>
    </RoundedCard>
</template>

<script lang="ts" setup>
import type { Entity, EntityEvent, FormModel, ModuleConfiguration } from "@/vf"
import { VfEditToolbar, VfForm, useModuleAction } from "@/vf"
import { provide, toRefs, type Ref } from "vue"
import VfBackButton from "../buttons/VfBackButton.vue"
import VfFormRest from "../form/VfFormRest.vue"

const props = withDefaults(
    defineProps<{
        config: ModuleConfiguration
        item?: Entity
        schemaRequestParams?: Record<string, any>
        autoRest?: boolean
        schemaUrl?: string
        multiStep?: boolean
    }>(),
    {
        autoRest: true,
        multiStep: false,
        item: () => ({}),
        schemaRequestParams: undefined,
        schemaUrl: undefined,
    },
)
const { config, item, schemaUrl, schemaRequestParams } = toRefs(props)

const emit = defineEmits<{
    (e: "save", event: EntityEvent): void | Promise<void>
    (e: "saved", entity: Entity): void
    (e: "error", entity: Entity): void
    (e: "model", model: Ref<FormModel>): void
}>()

provide("module-config", config.value)
provide("item", item.value)
provide("current-page", props.item?.id ? "edit" : "new")

const moduleAction = useModuleAction(config.value)

function saved(newEntity: Entity) {
    moduleAction.executeAfterAction(config.value.afterSaveAction, newEntity, true)
    emit("saved", newEntity)
}

const mode = config.value.show.page(item).mode
</script>

<template>
    <VfForm
        v-slot="{ formModel, schema, additionalData }"
        :config="config"
        :item="item"
        :schema-request-params="schemaRequestParams"
        :schema-url="schemaUrl"
        :multi-step="multiStep"
        @saved="saved"
        @save="$emit('save', $event)"
        @error="$emit('error', $event)"
        @model="$emit('model', $event)"
    >
        <slot name="default" v-bind="{ config, item, formModel, schema, additionalData, mode }">
            <div v-if="mode === 'router'" class="card-header">
                <slot name="header">
                    <div class="page-header">
                        <slot name="backbutton">
                            <VfBackButton></VfBackButton>
                        </slot>
                        <h1 v-t="config.readableName.plural"></h1>
                        <h2></h2>
                    </div>
                </slot>
            </div>
            <div v-if="mode === 'router'" class="card-toolbar">
                <VfEditToolbar :item="item" :config="config" />
            </div>
            <div>
                <slot name="content" v-bind="{ item, formModel, schema, additionalData }"></slot>

                <VfFormRest v-if="autoRest"></VfFormRest>

                <slot name="toolbar" v-bind="{ formModel }">
                    <VfEditToolbar v-if="mode !== 'router'" v-bind="{ item, config, formModel }" />
                </slot>
            </div>
        </slot>
    </VfForm>
</template>

<script lang="ts" setup>
import type { ModuleConfiguration } from "@/vf"
import { injectMandatory } from "@/vf"
import type { ListState } from "@/vf/components/crud/list/types"

const config = injectMandatory<ModuleConfiguration>(
    "module-config",
    "VfListSearch must be used inside an element that provides module-config like VfEdit or VfShow.",
)
const listState = injectMandatory<ListState>(
    "list-state",
    "VfListSearch must be used inside an element that provides list-state like VfList.",
)

const emit = defineEmits<{
    (e: "search"): void
}>()
</script>

<template>
    <form @submit.prevent="emit('search')">
        <div class="d-flex">
            <div class="search-icon">
                <i class="fas fa-fw fa-search"></i>
            </div>
            <input
                type="search"
                class="input-field !m-0"
                v-model="listState.searchValue"
                :placeholder="$t('crud:list.search')"
            />
            <select
                class="input-field"
                v-for="field in config.list.filterFields"
                :key="field.name"
                v-model="listState.filter[field.field]"
                @change="emit('search')"
            >
                <option :value="undefined">[ {{ field.name }} ]</option>
                <option v-for="item in field.items" :value="item.value" :key="item.value + item.name">
                    {{ item.name }}
                </option>
            </select>
        </div>
    </form>
</template>

<script setup lang="ts">
import { AnimateIf, useHttpClient } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { ref } from "vue"

const props = withDefaults(
    defineProps<{
        show: boolean
        url: string
    }>(),
    {
        show: false,
    },
)

const emit = defineEmits<{
    (event: "close"): void
}>()

/**
 * Send temporary password
 */
const { post } = useHttpClient()
const newPassword = ref<string>()
async function sendTemporaryPassword() {
    const { data } = await post<{ newPassword: string }>(props.url)
    newPassword.value = data.newPassword
}

function closeSendTemporaryPassword() {
    emit("close")
    newPassword.value = undefined
}
</script>

<template>
    <AnimateIf :if="show">
        <div class="bg-secondary p-5 rounded-xl shadow-lg mb-5 mt-3">
            <div class="mb-5 text-white">
                <b>
                    <slot />
                </b>

                <AnimateIf :if="newPassword !== undefined">
                    <div class="mt-2">
                        Passwort erfolgreich geändert. Das neue Passwort lautet:
                        <b class="text-primary">{{ newPassword }}</b>
                    </div>
                </AnimateIf>
            </div>

            <AnimateIf :if="newPassword === undefined">
                <div>
                    <FilledButton @click="sendTemporaryPassword">Passwort erzeugen</FilledButton>
                    <OutlineButton @click="closeSendTemporaryPassword">Abbrechen</OutlineButton>
                </div>
            </AnimateIf>

            <AnimateIf :if="newPassword !== undefined">
                <div>
                    <OutlineButton @click="closeSendTemporaryPassword">Schließen</OutlineButton>
                </div>
            </AnimateIf>
        </div>
    </AnimateIf>
</template>

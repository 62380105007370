import gsap from "gsap"
import type { App, DirectiveBinding } from "vue"

export function registerAnimateShow(vueApp: App) {
    vueApp.directive("animate-show", {
        // called before bound element's attributes
        // or event listeners are applied
        // called when the bound element's parent component
        // and all its children are mounted.
        mounted(el, binding) {
            el.style.overflow = "hidden"
            el.style.setProperty("padding", "0", "important")
            if (!binding.value) {
                el.style.height = 0
            }
        },
        // called after the parent component and
        // all of its children have updated
        updated(el, binding: DirectiveBinding) {
            if (binding.value === binding.oldValue) {
                return
            }

            gsap.to(el, {
                duration: 0.5,
                height: binding.value ? "auto" : 0,
            })
        },
    })
}

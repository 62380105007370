<script setup lang="ts">
import { useHttpClient } from "@/vf"
import BeatLoader from "vue-spinner/src/BeatLoader.vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"
import AppRouter from "./AppRouter.vue"

const { runningHttpRequests } = useHttpClient()

// // according to vue router documentation, you should be able to just do :leave-active-class="route.meta?.leaveActiveClass"
// // but, our typescript setup doesn't like that, so this is a workaround
// function toString(value: unknown): string {
//     return value as string
// }

// function toNumber(value: unknown): number {
//     return value as number
// }
// // :leave-active-class="toString(route.redirectedFrom?.meta?.leaveActiveClass)"
// // :leave-from-class="toString(route.redirectedFrom?.meta.leaveFromClass)"
// // :leave-to-class="toString(route.redirectedFrom?.meta.leaveToClass)"
// // :duration="toNumber(route.redirectedFrom?.meta.duration)"
</script>

<template>
    <Suspense>
        <template #default><AppRouter /></template>
        <template #fallback>
            <div class="fixed top-0 left-0 right-0 bottom-0 grid z-60 isolate place-items-center bg-white">
                <GridLoader color="#a5015b" />
            </div>
        </template>
    </Suspense>

    <Transition
        enter-active-class="transition duration-500 delay-500"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
    >
        <div class="fixed top-0 left-0 right-0 bottom-0 grid z-50 isolate" v-if="runningHttpRequests > 0">
            <div class="col-start-1 col-span-1 row-start-1 row-span-1 z-10 bg-secondary opacity-50"></div>
            <div class="col-start-1 col-span-1 row-start-1 row-span-1 z-20 grid place-items-center">
                <BeatLoader color="#a5015b" />
            </div>
        </div>
    </Transition>
</template>

import { defineComponent, type VNode, type VNodeArrayChildren } from "vue"

// not exported from vue
declare type RawChildren = string | number | boolean | VNode | VNodeArrayChildren | (() => any)

export function renderVNode(vnode: RawChildren) {
    return defineComponent({
        render: () => vnode,
    })
}

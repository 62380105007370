<script lang="ts" setup>
import { injectMandatory, type ModuleConfiguration } from "@/vf"
import ScNewButton from "./ScNewButton.vue"

const config = injectMandatory<ModuleConfiguration>(
    "module-config",
    "ScListToolbar must be used inside an element that provides module-config like VfEdit or VfShow.",
)
</script>

<template>
    <ScNewButton v-if="config.list.newButton()"></ScNewButton>
</template>

import type { Appointment } from "@/model/app/appointment"
import { normalizeModuleConfiguration } from "@/vf"
import { DateTime } from "luxon"

export function createModuleConfiguration(leadId: string) {
    return normalizeModuleConfiguration({
        apiBase: () => `/sales/appointment/${leadId}`,
        list: {
            orderField: "from",
            orderDirection: "desc",
            trClass: (entity: Appointment) =>
                "align-top " + (DateTime.fromISO(entity.from!) < DateTime.now() ? "opacity-50" : ""),
            onClick: null,
            editButton: false,
            showButton: false,
            deleteButton: false,
            fields: [
                {
                    name: "from",
                    title: "Zeitpunkt",
                    type: "datetime",
                    sortField: "a.from",
                },
                {
                    name: "text",
                    title: "Vermerk",
                    type: "string",
                    tdClass: () => "whitespace-pre-line",
                },
                {
                    name: "agentName",
                    title: "Agent",
                    type: "string",
                    getter: (entity: Appointment) => `${entity.agentName} (${entity.agentCode})`,
                },
            ],
        },
    })
}

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router"
import ChevronRight from "~icons/mdi/chevron-right"
import { useSalesUserStore, type SalesUserStore } from "../stores/sales-user-store"

const props = withDefaults(defineProps<{ to: RouteLocationRaw; check?: (user: SalesUserStore) => boolean }>(), {
    check: () => true,
})
const userStore = useSalesUserStore()
</script>

<template>
    <RouterLink
        :to="props.to"
        v-if="check(userStore)"
        class="mx-5 px-5 block py-3 transition-all rounded-lg text-white hover:text-white"
        active-class="bg-primary-tint"
    >
        <span class="chevron"><ChevronRight class="inline" /></span>
        <span class="label"><slot /></span>
    </RouterLink>
</template>

<style scoped>
.chevron {
    width: 0;
    opacity: 0;
    transform: translateX(-20px);
}

.label,
.chevron {
    display: inline-block;
    transition: 150ms ease-in-out all;
}

a:hover .chevron,
.router-link-active .chevron {
    opacity: 1;
    transform: translateX(0);
}

a:hover .label,
.router-link-active .label {
    transform: translateX(20px);
}
</style>

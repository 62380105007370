<script lang="ts" setup>
import type { DialogController } from "@/vf"
import { ref, toRefs } from "vue"

const props = defineProps<{ controller: DialogController<any> }>()

const { controller } = toRefs(props)
const { requiredValue, title, message, icon } = controller.value.props

const value = ref("")

function submit() {
    if (value.value != requiredValue) {
        return
    }

    controller.value.ok(value.value)
}
</script>

<template>
    <form @submit.prevent="submit()">
        <h4 class="modal-title mb-5">
            <i v-if="icon" class="fa fa-fw fa-${icon}"></i>
            {{ title }}
        </h4>

        <div class="py-2 px-3">
            <label v-t="{ path: message, args: { value: requiredValue } }" for="dialogInput"></label>
            <input id="dialogInput" v-model="value" type="text" class="form-control" />
        </div>

        <div class="d-flex mt-5">
            <button
                v-t="'dialog.button.cancel'"
                type="button"
                class="btn btn-secondary dialog-footer-btn"
                @click="controller.cancel()"
            ></button>
            <button
                v-t="'dialog.button.ok'"
                type="submit"
                class="btn btn-danger dialog-footer-btn btn-raised"
                :disabled="value != requiredValue"
            ></button>
        </div>
    </form>
</template>

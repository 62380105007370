<script setup lang="ts">
import { provide, ref, useSlots, watchEffect } from "vue"
import ScTab from "./ScTab.vue"

const slots = useSlots()
const tabs = (slots.default?.().filter(x => x.type === ScTab) ?? []).map(x => ({
    title: x.props?.title,
    id: x.props?.id ?? x.props?.title,
}))
const activeTab = ref<string | number | null>(null)

watchEffect(() => {
    if (activeTab.value === null) {
        activeTab.value = tabs[0]?.id ?? tabs[0]?.title ?? null
    }
})

provide("ScTabGroup:activeTab", activeTab)
</script>

<template>
    <div>
        <div class="border-b">
            <div class="flex gap-3">
                <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="cursor-pointer py-4 px-7 rounded-t"
                    @click="activeTab = tab.id"
                    :class="activeTab == tab.id ? 'bg-primary text-white' : 'bg-slate-100'"
                >
                    {{ tab.title }}
                </div>
            </div>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<template>
    <div class="bg-secondary grow text-white flex items-center bg py-2 lg:py-10 bg-cover xl:bg-[length:65%]">
        <div class="container mx-auto" v-bind="$attrs">
            <slot />
        </div>
    </div>
</template>

<style scoped>
.bg {
    background-image: url(@/wallet/assets/background.png);
    background-position: right;
    background-repeat: no-repeat;
}
</style>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue"

export default defineComponent({
    props: {
        component: {
            type: String,
            required: true,
        },
        props: {
            type: Object,
            require: false,
            default: () => ({}),
        },
    },
    computed: {
        componentInstance() {
            return defineAsyncComponent(() => import(/* @vite-ignore */ this.component))
        },
    },
})
</script>

<template>
    <component :is="componentInstance" v-bind="props"></component>
</template>

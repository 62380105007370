<script setup lang="ts">
import AutoHeightTextArea from "@/common/components/AutoHeightTextArea.vue"
import type { Appointment } from "@/model/app/appointment"
import type { Customer } from "@/model/app/customer"
import type { Lead } from "@/model/app/lead"
import { ScInlineList } from "@/sales/components/crud"
import { $datetime, useHttpClient } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { computed, ref } from "vue"
import { createModuleConfiguration } from "./lead-appointment.config"

const props = defineProps<{
    lead: Lead | Customer
    leadId: string
}>()

const emit = defineEmits<{
    (event: "refresh"): void
}>()

const config = computed(() => createModuleConfiguration(props.leadId!))

const list = ref<typeof ScInlineList>()
const http = useHttpClient()
const newAppointmentDate = ref()
const newAppointmentText = ref("")

async function saveNewAppointment() {
    if (newAppointmentDate.value === undefined) {
        return
    }

    await http.post(`/sales/appointment/${props.leadId}`, {
        from: newAppointmentDate.value,
        text: newAppointmentText.value,
    })

    await list.value?.refresh()
    emit("refresh")
    newAppointmentDate.value = undefined
    newAppointmentText.value = ""
}

async function deleteAppointment(appointment: Appointment) {
    await http.delete(`/sales/appointment/${props.leadId}/${appointment.id}`)
    await list.value?.refresh()
}
</script>

<template>
    <div class="flex flex-col gap-10">
        <div class="">
            <b>Nächster Termin:</b>
            <div v-if="lead.nextAppointment">
                {{ $datetime(lead.nextAppointment?.from) }}
            </div>
            <div v-else class="opacity-50">Kein nächster Termin</div>

            <div class="mt-5"></div>
            <b>Neuer Termin:</b>
            <form @submit.prevent="saveNewAppointment">
                <input type="datetime-local" class="input-field" v-model="newAppointmentDate" />
                <AutoHeightTextArea placeholder="Vermerk" v-model="newAppointmentText" />

                <OutlineButton type="submit" class="w-full mt-4">Speichern</OutlineButton>
            </form>
        </div>
        <div class="grow">
            <ScInlineList v-bind="{ config }" ref="list">
                <template #afterListButtons="{ item }">
                    <OutlineButton @click="deleteAppointment(item)">Löschen</OutlineButton>
                </template>
            </ScInlineList>
        </div>
    </div>
</template>

import LeadEditView from "@/sales/views/lead/LeadEditView.vue"
import LeadShowView from "@/sales/views/lead/LeadShowView.vue"
import { normalizeModuleConfiguration, type ModuleConfigurationOptions } from "@/vf"
import { computed, type Ref } from "vue"

export function createModuleConfiguration(showOnlyMyLeads: Ref<boolean> | undefined = undefined) {
    const config: ModuleConfigurationOptions = {
        apiBase: () => "/sales/lead",
        mode: "in-table",
        list: {
            fieldsCustomizable: true,
            requestParams: computed(() => ({ showOnlyMyLeads: showOnlyMyLeads?.value ? "1" : "0" })),
            fields: [
                { name: "number", title: "Nr.", type: "string" },
                { name: "lastName", title: "Nachname", type: "string", sortField: "a.lastName" },
                { name: "firstName", title: "Vorname", type: "string", sortField: "a.firstName" },
                { name: "company", title: "Firma", type: "string", defaultHidden: true, sortField: "a.company" },
                { name: "email", title: "E-Mail", type: "string" },
                { name: "phone", title: "Telefon", type: "string" },
                { name: "agentCode", title: "Agent", type: "string", sortField: "agent.agentCode" },
                { name: "createdAt", title: "Erstellt am", type: "date", sortField: "a.createdAt" },
                { name: "birthdate", title: "Geburtstag", type: "date", sortField: "a.birthdate" },
            ],
        },
        show: LeadShowView,
        edit: LeadEditView,
        new: LeadEditView,
    }

    return normalizeModuleConfiguration(config)
}

<script setup lang="ts">
import type { BonusTokenCampaign } from "@/model/app/bonus-token-campaign"
import { ScEdit } from "@/sales/components/crud"
import { VfFormRow } from "@/vf"
import { DateTime } from "luxon"
import { createModuleConfiguration } from "./bonus-campaigns.config"

const config = createModuleConfiguration()

const newCampaign: Partial<BonusTokenCampaign> = {
    validFrom: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toISO().split(".")[0],
    validUntil: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).plus({ week: 2 }).toISO().split(".")[0],
    minimumWalletBalance: "600",
}
</script>

<template>
    <div>
        <div class="card-title">
            <b>Neue</b>
            Bonus-Kampagne
        </div>

        <ScEdit v-bind="{ config, item: newCampaign }">
            <VfFormRow name="name" />
            <VfFormRow name="text" />
            <VfFormRow name="tokenAmount" />

            <VfFormRow name="minimumWalletBalance" />

            <div class="flex gap-5">
                <div class="flex-grow">
                    <VfFormRow name="validFrom" />
                </div>
                <div class="flex-grow">
                    <VfFormRow name="validUntil" />
                </div>
            </div>
        </ScEdit>
    </div>
</template>

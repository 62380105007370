<script lang="ts" setup>
import type { VfFormInject } from "@/vf/components/crud/form/types"
import type { FormKitNode } from "@formkit/core"
import { computed, inject } from "vue"
import VfFormRow from "./VfFormRow.vue"

const form = inject<VfFormInject>("vf-form")
if (!form) {
    throw Error("VfFormRendered must be used inside VfForm")
}
// filter out all already rendered fields
const filteredSchema = computed(() => {
    const generate = (prefix: string, schema: any[]): FormKitNode[] => {
        const generated = []
        for (const s of schema) {
            if (form.renderedList.value.includes(prefix + s.name)) {
                continue
            }
            if (s.$formkit === "group") {
                generated.push({
                    $formkit: "group",
                    name: s.name,
                    children: generate(prefix + s.name + ".", s.children),
                })
            } else {
                generated.push(s)
            }
        }
        return generated
    }
    return generate("", form.schema.value)
})
</script>

<template>
    <VfFormRow v-for="s in filteredSchema" :key="s.name" :schema="s" :is-rest="true" :data="form.formModel"></VfFormRow>
</template>

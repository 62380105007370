<script setup lang="ts">
import type { Appointment } from "@/model/app/appointment"
import type { Lead } from "@/model/app/lead"
import { useHttpGet, VfDropdown } from "@/vf"
import { onKeyStroke } from "@vueuse/core"
import { DateTime } from "luxon"
import { computed, ref } from "vue"
import Fa6SolidCakeCandles from "~icons/fa6-solid/cake-candles"
import Fa6SolidCircleChevronLeft from "~icons/fa6-solid/circle-chevron-left"
import Fa6SolidCircleChevronRight from "~icons/fa6-solid/circle-chevron-right"
import { calculateAgeFromBirthDay } from "../util/calculators"

const props = withDefaults(
    defineProps<{
        compact?: boolean
    }>(),
    {
        compact: false,
    },
)

/*
 * Calendar rendering
 */
// create the initial date from day / month / year without time to always have a DateTime object with time 00:00:00
// and the right timezone
const now = DateTime.now()
const initialDate = DateTime.fromObject({
    year: now.year,
    month: now.month,
    day: now.day,
}).setLocale("de")

const selectedDate = ref(initialDate)

// get all days in a month as DateTime with time 00:00:00
function getAllDatesInMonth(input: DateTime): DateTime[] {
    return [...Array(input.daysInMonth).keys()].map(i =>
        DateTime.fromObject({ month: input.month, year: input.year, day: i + 1 }),
    )
}

// previous and next are relevant to show days from last and next month to fill weeks
const previousMonth = computed(() => selectedDate.value.minus({ month: 1 }))
const nextMonth = computed(() => selectedDate.value.plus({ month: 1 }))

const daysInPreviousMonth = computed(() => getAllDatesInMonth(previousMonth.value))
const daysInMonth = computed(() => getAllDatesInMonth(selectedDate.value))
const daysInNextMonth = computed(() => getAllDatesInMonth(nextMonth.value))

// the days that are relevant to show from the previous and next month
const relevantDaysInPreviousMonth = computed(() => {
    const firstDayOfMonth = selectedDate.value.startOf("month")
    const daysToFill = firstDayOfMonth.weekday - 1
    // .slice(0) returns the full array, so explicitly return an empty array if there are no days to fill
    return daysToFill === 0 ? [] : daysInPreviousMonth.value.slice(-daysToFill)
})

const relevantDaysInNextMonth = computed(() => {
    const lastDayOfMonth = selectedDate.value.endOf("month")
    const daysToFill = 7 - lastDayOfMonth.weekday
    return daysInNextMonth.value.slice(0, daysToFill)
})

/*
 * Date selection
 */
function selectDay(day: DateTime) {
    // when in compact mode, don't change the month
    if (props.compact && day.month !== selectedDate.value.month) {
        return
    }

    selectedDate.value = day
}

function plusMonth(diff: number) {
    selectedDate.value = selectedDate.value.plus({ month: diff })
}

onKeyStroke("ArrowLeft", () => plusMonth(-1))
onKeyStroke("ArrowRight", () => plusMonth(1))

/*
 * Data loading
 */
const { data: events } = useHttpGet<{ birthdays: Lead[]; appointments: Appointment[] }>(
    computed(() => `/sales/calendar/${selectedDate.value.toFormat("yyyy/MM")}`),
    false,
)

type CalendarEvent = {
    id: string
    type: "birthday" | "appointment"
    title: string
    from: DateTime
    until: DateTime
    text?: string
}

/** Map of string with date format "yyyy-MM-dd" to CalendarEvent sorted by "from" date */
const eventsByDate = computed(() => {
    const _eventsByDate: Record<string, CalendarEvent[]> = {}

    for (const birthday of events.value?.birthdays ?? []) {
        const date = DateTime.fromISO(birthday.birthdate!).set({ year: selectedDate.value.year })
        const key = date.toFormat("yyyy-MM-dd")
        _eventsByDate[key] ??= []
        _eventsByDate[key].push({
            id: birthday.id!,
            type: "birthday",
            title: `${birthday.firstName} ${birthday.lastName} (${calculateAgeFromBirthDay(
                birthday.birthdate!,
                date,
            )})`,
            from: date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            until: date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
        })
    }

    for (const appointment of events.value?.appointments ?? []) {
        const date = DateTime.fromISO(appointment.from!)
        const key = date.toFormat("yyyy-MM-dd")
        _eventsByDate[key] ??= []
        _eventsByDate[key].push({
            id: appointment.id!,
            type: "appointment",
            title: `${appointment.lead?.firstName ?? ""} ${appointment.lead?.lastName ?? ""}`,
            from: date,
            until: DateTime.fromISO(appointment.until!),
            text: appointment.text!,
        })
    }

    return _eventsByDate
})
</script>

<template>
    <div class="flex flex-col">
        <div class="flex justify-center items-center py-3" v-if="!compact">
            <div class="text-xl">
                <Fa6SolidCircleChevronLeft class="cursor-pointer" @click="plusMonth(-1)" />
            </div>
            <div class="px-10 w-64 text-center">
                {{ selectedDate.toFormat("MMMM yyyy") }}
            </div>
            <div class="text-xl">
                <Fa6SolidCircleChevronRight class="cursor-pointer" @click="plusMonth(1)" />
            </div>
        </div>
        <div class="grow grid grid-cols-7 border-l border-t auto-cols-fr auto-rows-fr grid-rows-[min-content_1fr]">
            <div
                v-for="dayName of ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']"
                :key="dayName"
                class="font-bold p-3 border-r border-b-2 text-center"
            >
                {{ dayName }}
            </div>
            <div
                v-for="date in [...relevantDaysInPreviousMonth, ...daysInMonth, ...relevantDaysInNextMonth]"
                :key="date.toISO()"
                class="border-r border-b p-2"
                @click="selectDay(date)"
                :class="{ 'opacity-50': !date.hasSame(selectedDate, 'month') }"
            >
                <div :class="{ 'text-primary font-bold': date.equals(initialDate) }">{{ date.day }}.</div>
                <template v-for="event of eventsByDate[date.toFormat('yyyy-MM-dd')] ?? []" :key="event.id">
                    <VfDropdown>
                        <template #default="{ toggleDropdown }">
                            <div
                                class="flex bg-secondary text-white my-1 rounded py-1 px-2 text-sm gap-1"
                                :class="{ 'opacity-50': event.until < DateTime.local() }"
                                @click="event.text ? toggleDropdown() : null"
                            >
                                <Fa6SolidCakeCandles v-if="event.type === 'birthday'" />
                                <div v-if="event.type === 'appointment'" class="text-primary tabular-nums">
                                    {{ event.from.toFormat("HH:mm") }}
                                </div>
                                <div class="grow">{{ event.title }}</div>
                            </div>
                        </template>
                        <template #menu>
                            <div
                                class="inline-block z-0 p-5 border rounded-xl bg-white shadow-2xl ml-3 whitespace-pre -translate-y-full"
                            >
                                {{ event.text }}
                            </div>
                        </template>
                    </VfDropdown>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ScList from "@/sales/components/crud/ScList.vue"
import ShowOnlyMyLeadsFilter from "@/sales/components/ShowOnlyMyLeadsFilter.vue"
import { ref } from "vue"
import { createModuleConfiguration } from "./transactions.config"

const showOnlyMyLeads = ref(false)
const config = createModuleConfiguration(showOnlyMyLeads)
</script>

<template>
    <ScList :config="config">
        <template #custom-filter><ShowOnlyMyLeadsFilter v-model="showOnlyMyLeads" /></template>
        <template #title>Aufträge</template>
    </ScList>
</template>

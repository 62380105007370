<script lang="ts" setup>
import type { VfFormInject } from "@/vf/components/crud/form/types"
import { inject, onMounted, onUnmounted } from "vue"

const props = defineProps<{
    name: string
}>()

const form = inject<VfFormInject>("vf-form")
if (!form) {
    throw Error("VfFormRendered must be used inside VfForm")
}
form.renderedList.value.push(props.name)

onMounted(() => {
    if (!form.renderedList.value.includes(props.name)) {
        form.renderedList.value.push(props.name)
    }
})

onUnmounted(() => {
    if (form.renderedList.value.includes(props.name)) {
        form.renderedList.value.splice(form.renderedList.value.indexOf(props.name), 1)
    }
})
</script>

<template>
    <slot />
</template>

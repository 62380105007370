<script setup lang="ts">
import { computed } from "vue"
import BouncyOverlaySpinner from "./BouncyOverlaySpinner.vue"

const props = withDefaults(
    defineProps<{
        color?: "white" | "primary" | "secondary" | "modal"
        backLink?: string
        backEmit?: boolean
        spinner?: boolean
        defaultClass?: string
    }>(),
    {
        color: "white",
        spinner: false,
        backEmit: false,
        defaultClass: "p-5 md:p-10",
    },
)

const emit = defineEmits<{
    (event: "back"): void
}>()

const modalClasses = computed(() => {
    switch (props.color) {
        case "white":
            return "bg-white text-modal-text"
        case "modal":
            return "bg-modal text-modal-text"
        case "primary":
            return "bg-primary text-white"
        case "secondary":
            return "bg-secondary text-white"
        default:
            return ""
    }
})
</script>

<template>
    <div class="rounded shadow overflow-y-hidden" :class="modalClasses">
        <BouncyOverlaySpinner :show="spinner">
            <template #default>
                <div :class="defaultClass">
                    <div class="flex justify-between">
                        <h2 class="text-2xl font-bold italic">
                            <slot name="title" />
                        </h2>
                        <RouterLink :to="backLink" v-if="backLink" class="underline">zurück</RouterLink>
                        <span v-if="backEmit" @click="emit('back')" class="underline cursor-pointer">zurück</span>
                    </div>
                    <slot />
                </div>
            </template>
            <template #spinner>
                <slot name="spinner" />
            </template>
        </BouncyOverlaySpinner>
    </div>
</template>

<script lang="ts" setup>
import type { Customer } from "@/model/app/customer"
import type { Lead } from "@/model/app/lead"
import { AnimateIf, useHttpClient } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { AxiosError } from "axios"
import { ref } from "vue"
import { useSalesUserStore } from "../stores/sales-user-store"

const props = defineProps<{
    item: Customer | Lead
    leadId: string
}>()

const emit = defineEmits<{
    (event: "agent-changed", payload: { newAgentCode: string }): void
}>()
const { isAdmin } = useSalesUserStore()
const http = useHttpClient()

const showCodeInput = ref(false)
const newAgentCode = ref("")

async function changeAgent() {
    try {
        await http.post(`/sales/lead/${props.leadId}/change-agent`, { agentCode: newAgentCode.value })
    } catch (e) {
        console.error(e)
        if (e instanceof AxiosError && e.response?.status === 404) {
            alert("Agent nicht gefunden")
            return
        }
        alert("Unerwarteter Fehler")
        return
    }
    showCodeInput.value = false
    newAgentCode.value = ""
    emit("agent-changed", { newAgentCode: newAgentCode.value })
}
</script>

<template>
    <div>
        <b>Agent</b>
        <div class="mb-2">
            <AnimateIf :if="!showCodeInput">
                {{ item.agent?.firstName }} {{ item.agent?.lastName }}
                <br />
                {{ item.agent?.agentCode }}
                <br />
                <OutlineButton v-if="isAdmin" @click="showCodeInput = !showCodeInput">Agent ändern</OutlineButton>
            </AnimateIf>
            <AnimateIf :if="showCodeInput">
                <form @submit.prevent="changeAgent" class="bg-secondary p-5 rounded-xl shadow-lg mb-5">
                    <div class="mb-5 text-white">
                        <b>Wie ist der Agentur-Code des neuen Agenten?</b>
                    </div>

                    <input type="text" class="input-field" v-model="newAgentCode" />
                    <FilledButton type="submit">Ändern</FilledButton>
                    <OutlineButton @click="showCodeInput = false">Abbrechen</OutlineButton>
                </form>
            </AnimateIf>
        </div>
    </div>
</template>

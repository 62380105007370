import { createPinia } from "pinia"
import { createApp } from "vue"
import { plugin as formkit } from "@formkit/vue"
import createFormkitConfig from "./formkit.config"

import App from "./App.vue"
import router from "./router"

import { registerAnimateShow } from "@/vf/plugins/animate-show"
import { createI18n } from "vue-i18n"
import "./assets/main.css"

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(formkit, createFormkitConfig())

const i18n = createI18n({
    legacy: false,
    locale: "de",
    messages: {
        de: {
            "crud:list.entries_per_page": "Einträge pro Seite",
            "crud:button.new": "Anlegen",
            "crud:list.search": "Suchen...",
            "crud:list.page_info": "Seite {page} von {pageCount}, {totalItems} Einträge gesamt",
            "crud:list.actions": "Aktionen",
        },
    },
})

app.use(i18n)
app.use(registerAnimateShow)

app.mount("#app")

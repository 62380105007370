import { DateTime } from "luxon"
import type { App } from "vue"

export const $objectKeys = (object: object) => Object.keys(object)
export const $objectMap = (object: object) => new Map(Object.entries(object))
export const $objectEntries = (object: object) => Object.entries(object)
export const $objectValues = (object: object) => Object.values(object)

function normalDate(date: AnyDateValue) {
    let dateTime
    if (typeof date === "string") {
        dateTime = DateTime.fromISO(date)
    }

    if (date instanceof Date) {
        dateTime = DateTime.fromJSDate(date)
    }

    if (date instanceof DateTime) {
        dateTime = date
    }
    if (!dateTime) {
        throw new Error(`normalDate expects a Date, DateTime or string, got ${typeof date}`)
    }
    return dateTime.setLocale("de") // TODO locale?
}

function formatNormalDate(date: AnyDateValue, format: string) {
    if (date === undefined || date === null) {
        return "-"
    }

    return normalDate(date).toFormat(format)
}

export type AnyDateValue = Date | DateTime | string | null | undefined

// 01.01.1970
export const $date = (value: AnyDateValue) => formatNormalDate(value, "dd.MM.yyyy")
// 01. Januar 1970
export const $longDate = (value: AnyDateValue) => formatNormalDate(value, "dd. LLLL yyyy")
// 01. Januar 1970 12:00
export const $datetime = (value: AnyDateValue) => formatNormalDate(value, "dd. LLLL yyyy HH:mm")
// 01.01.1970 12:00
export const $shortdatetime = (value: AnyDateValue) => formatNormalDate(value, "dd.MM.yyyy HH:mm")
// 12:00
export const $time = (value: AnyDateValue) => formatNormalDate(value, "HH:mm")
// format date with luxon format string
export const $formatDate = (value: AnyDateValue, format: string) => formatNormalDate(value, format)

// custom date with intl options api
export const $customDate = (value: string, format: Intl.DateTimeFormatOptions) =>
    DateTime.fromISO(value).toLocaleString(format)

// vor 1 Stunde
export function $relativeTime(value: string | Date) {
    const minutes = 60
    const hours = 60 * minutes

    const now = new Date()
    const then = value instanceof Date ? value : new Date(value)
    const diff = Math.abs(now.getTime() - then.getTime()) / 1000
    const past = now > then

    if (diff >= 24 * hours) {
        // show absolute date time for timestamps more than 24 hours ago
        return $shortdatetime(then.toISOString())
    }

    if (diff > 60 * minutes) {
        const h = Math.floor(diff / hours)
        return (past ? "vor " : "in ") + h + " Stunde" + (h == 1 ? "" : "n")
    }

    if (diff > minutes) {
        const h = Math.floor(diff / minutes)
        return (past ? "vor " : "in ") + h + " Minute" + (h == 1 ? "" : "n")
    }

    return past ? "gerade eben" : "gleich"
}

// was auch immer das ergibt...
// export function $timediff(value: string | Date) {
//     const minutes = 60
//     const hours = 60 * minutes

//     if (value[0] && value[1]) {
//         const d1 = (value[0] instanceof Date ? value : new Date(value[0])) as Date
//         const d2 = (value[1] instanceof Date ? value : new Date(value[1])) as Date
//         const diff = Math.abs(d1.getTime() - d2.getTime()) / 1000

//         let h: number | string = Math.floor(diff / hours)
//         let m: number | string = Math.floor((diff - h * hours) / minutes)
//         let s: number | string = Math.floor(diff - h * hours - m * minutes)

//         if (h < 10) h = "0" + h
//         if (m < 10) m = "0" + m
//         if (s < 10) s = "0" + s

//         return h + ":" + m + ":" + s
//     }

//     return "-"
// }

export function $fileSize(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Byte"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toLocaleString("de") + " " + sizes[i]
}

export function $fileType(t: string) {
    if (t === "application/pdf") {
        return "PDF"
    }

    if (t.indexOf("image/") === 0) {
        return "Bild"
    }

    return t
}

export function $fileTypeIcon(t: string) {
    if (t === "application/pdf") {
        return "far fa-file-pdf"
    }

    if (t && t.indexOf("image/") === 0) {
        return "far fa-file-image"
    }

    return "far fa-file"
}

// utf8 ✔ or ✖,
export const $bool = (value: boolean) => (value ? "\u2714" : "\u2716")

const defaultCurrencyFormatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
})

export function $currency(
    value: string | number | bigint | undefined | null,
    divisor = 100,
    options?: Intl.NumberFormatOptions,
) {
    let numberValue: number | bigint

    if (value === null || value === undefined) {
        return ""
    }

    if (typeof value === "string") {
        numberValue = parseInt(value)
    } else {
        numberValue = value
    }

    if (typeof numberValue === "number" && isNaN(numberValue)) {
        return ""
    }

    let formatter = defaultCurrencyFormatter

    if (options) {
        formatter = new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            ...options,
        })
    }

    if (typeof numberValue === "bigint") {
        return formatter.format(numberValue / BigInt(divisor))
    }

    return formatter.format(numberValue / divisor)
}

export function $number(value: string | number, decimalPlaces = 2) {
    return Number(value).toLocaleString("de", {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces,
    })
}

export function registerAureliaCompat(vueApp: App) {
    vueApp.mixin({
        methods: {
            $objectKeys,
            $objectMap,
            $objectEntries,
            $objectValues,
            $date,
            $datetime,
            $shortdatetime,
            $time,
            $customDate,
            $relativeTime,
            // $timediff,
            $fileSize,
            $fileType,
            $fileTypeIcon,
            $bool,
            $currency,
            $number,
        },
    })
}

<script setup lang="ts">
import {
    VfFeedbackError,
    VfFeedbackInfo,
    VfFeedbackLoading,
    VfFeedbackProgress,
    VfFeedbackQuestion,
    VfFeedbackSuccess,
} from "@/vf/components"
import { computed, ref } from "vue"
import type { DialogButton, DialogController } from "."

const props = defineProps<{
    controller: DialogController<any>
}>()
const options = computed(() => props.controller.props)

function buttonClicked(button: DialogButton) {
    if (button.handler) {
        button.handler(
            result => props.controller.ok(result, text.value),
            () => props.controller.cancel(),
            text.value,
        )
    }
}

const text = ref("")
</script>

<template>
    <VfFeedbackSuccess v-if="options.type === 'success'"></VfFeedbackSuccess>
    <VfFeedbackError v-if="options.type === 'error'"></VfFeedbackError>
    <div v-if="options.type === 'progress'">
        <VfFeedbackProgress
            v-if="options.progress!.running"
            :progress="options.progress!.progress"
        ></VfFeedbackProgress>
        <VfFeedbackError v-if="options.progress!.error"></VfFeedbackError>
        <VfFeedbackSuccess v-if="!options.progress!.running && !options.progress!.error"></VfFeedbackSuccess>
    </div>
    <VfFeedbackInfo v-if="options.type === 'info'"></VfFeedbackInfo>
    <VfFeedbackQuestion v-if="options.type === 'question'"></VfFeedbackQuestion>
    <VfFeedbackLoading v-if="options.type === 'loading'"></VfFeedbackLoading>

    <div v-if="typeof options.message === 'string'" class="text-center pb-3">
        <span v-if="!options.translate">{{ options.message }}</span>
        <span v-if="options.translate" v-t="{ path: options.message, args: options.messageVars }"></span>
    </div>
    <div
        v-else-if="typeof options.message === 'object' && options.message.outerHTML"
        v-html="options.message.outerHTML"
    ></div>
    <div v-else>
        <component :is="options.message"></component>
    </div>

    <textarea
        v-if="options.inputType === 'textarea'"
        v-model="text"
        class="form-control mb-3"
        :placeholder="$t(typeof options.placeholder === 'string' ? options.placeholder : '')"
    ></textarea>
    <input
        v-if="options.inputType === 'input'"
        v-model="text"
        type="text"
        class="form-control mb-3"
        :placeholder="$t(typeof options.placeholder === 'string' ? options.placeholder : '')"
    />
    <input
        v-if="options.inputType === 'number'"
        v-model.number="text"
        type="number"
        class="form-control mb-3"
        :placeholder="$t(typeof options.placeholder === 'string' ? options.placeholder : '')"
    />
    <div v-if="options.buttons" class="text-right">
        <button
            v-for="(button, index) of options.buttons"
            :key="index"
            :class="button.class || 'btn btn-secondary'"
            @click="buttonClicked(button)"
        >
            <i v-if="button.icon" :class="button.icon"></i>
            <span v-if="button.translate" v-t="button.label"></span>
            <span v-if="!button.translate">{{ button.label }}</span>
        </button>
    </div>
</template>

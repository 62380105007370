<script setup lang="ts">
import type { Customer } from "@/model/app/customer"
import { ScShowToolbar } from "@/sales/components/crud"
import ScShow from "@/sales/components/crud/ScShow.vue"
import LeadShowAgent from "@/sales/components/LeadShowAgent.vue"
import SendTemporaryPasswordConfirm from "@/sales/components/SendTemporaryPasswordConfirm.vue"
import ScTab from "@/sales/components/tabs/ScTab.vue"
import ScTabGroup from "@/sales/components/tabs/ScTabGroup.vue"
import { useSalesUserStore } from "@/sales/stores/sales-user-store"
import { $date, AnimateIf, useHttpClient, useInlineTable, useLoadEntity } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { ref } from "vue"
import MaterialSymbolsVerified from "~icons/material-symbols/verified"
import LeadAgentHistoryList from "../lead/agent-history/LeadAgentHistoryList.vue"
import LeadAppointmentList from "../lead/appointment/LeadAppointmentList.vue"
import LeadNoteListWithEnter from "../lead/note/LeadNoteListWithEnter.vue"
import { createModuleConfiguration } from "./customers.config"
import CustomerTransactionListView from "./CustomerTransactionListView.vue"

const props = defineProps<{ id: string }>()
const config = createModuleConfiguration()
const { item, refresh } = await useLoadEntity<Customer>(props.id, config)
const { post } = useHttpClient()
const { isAdmin } = useSalesUserStore()
const inlineTable = useInlineTable()

async function verify() {
    await post(`/sales/customer/${props.id}/verify`)
    await refresh()
}

/*
 * Bonus Token
 */
const showGiveBonusToken = ref(false)
const giveBonusTokenAmount = ref()
const transactionList = ref<typeof CustomerTransactionListView>()

async function submitGiveBonusToken() {
    if (giveBonusTokenAmount.value === undefined) {
        return
    }

    await post(`/sales/customer/${props.id}/give-bonus-token`, { bonusTokenAmount: giveBonusTokenAmount.value })
    await refresh()
    transactionList.value?.refresh()
    giveBonusTokenAmount.value = undefined
    showGiveBonusToken.value = false
    inlineTable?.emit("refresh")
}

async function sendWelcomeMail() {
    await post(`/sales/lead/${item.value.leadId}/send-welcome-mail`)
    await refresh()
}

/*
 * Temporary Password
 */
const showSendTemporaryPassword = ref(false)

/**
 * Agent association / history
 */
const agentHistoryList = ref<typeof LeadAgentHistoryList>()

function refreshAgentList() {
    refresh()
    agentHistoryList.value?.refresh()
}
</script>

<template>
    <ScShow v-bind="{ config, item }">
        <template #default>
            <div class="flex justify-between items-center">
                <div>
                    <div class="card-title !mb-0">
                        {{ item.salutation }}
                        {{ item.fullName }}
                    </div>
                    <div class="italic opacity-50">geboren am {{ $date(item.birthdate) }}</div>
                </div>
                <div class="text-right">
                    <div class="text-primary flex items-center justify-end gap-1" v-if="item.isVerified">
                        <MaterialSymbolsVerified class="inline-block" />
                        <span class="inline-block">Verifiziert</span>
                    </div>
                    <div class="italic opacity-50">Angemeldet am {{ $date(item.createdAt) }}</div>
                </div>
            </div>

            <Transition
                leave-active-class="transition duration-300"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-5"
            >
                <div class="p-5 mt-2 bg-secondary text-white rounded shadow-lg" v-if="!item.isVerified">
                    <div class="mb-5">Der Kunde ist noch nicht verifiziert.</div>

                    <FilledButton @click="verify">Jetzt freischalten</FilledButton>
                </div>
            </Transition>

            <div class="grid grid-cols-3 mt-10">
                <div>
                    <b>Adresse</b>
                    <div>{{ item.fullName }}</div>
                    <div>{{ item.company }}</div>
                    <div>{{ item.street }}</div>
                    <div>{{ item.zipCode }} {{ item.city }}</div>
                </div>

                <div>
                    <b>Telefon</b>
                    <div class="mb-2">
                        <a class="link" :href="`tel:${item.phone}`">{{ item.phone }}</a>
                    </div>

                    <b>E-Mail</b>
                    <div>
                        <a class="link" :href="`mailto:${item.email}`">{{ item.email }}</a>
                    </div>
                </div>

                <LeadShowAgent :item="item" :lead-id="item.leadId!" @agent-changed="refreshAgentList()"></LeadShowAgent>
            </div>

            <ScTabGroup class="mt-10">
                <ScTab title="Notizen">
                    <LeadNoteListWithEnter :lead-id="item.leadId!" />
                </ScTab>

                <ScTab title="Transaktionen">
                    <OutlineButton class="mb-3" v-if="isAdmin" @click="showGiveBonusToken = !showGiveBonusToken">
                        Bonus-Token buchen
                    </OutlineButton>
                    <AnimateIf :if="isAdmin && showGiveBonusToken">
                        <form @submit.prevent="submitGiveBonusToken" class="bg-secondary p-5 rounded-xl shadow-lg mb-5">
                            <div class="mb-5 text-white">
                                <b>Wie viele Bonus-Token sollen dem Kunden gutgeschrieben werden?</b>
                            </div>

                            <input type="number" class="input-field" v-model="giveBonusTokenAmount" />
                            <FilledButton type="submit">Token gutschreiben</FilledButton>
                            <OutlineButton @click="showGiveBonusToken = false">Abbrechen</OutlineButton>
                        </form>
                    </AnimateIf>

                    <CustomerTransactionListView :customer-id="props.id" ref="transactionList" />
                </ScTab>

                <ScTab title="Termine">
                    <LeadAppointmentList :lead="item" :lead-id="item.leadId!" @refresh="refresh" />
                </ScTab>

                <ScTab title="Agenten">
                    <LeadAgentHistoryList :lead-id="item.leadId!" ref="agentHistoryList" />
                </ScTab>
            </ScTabGroup>
        </template>
        <template #toolbar>
            <div class="flex">
                <ScShowToolbar :item="item" />
                <OutlineButton class="" @click="showSendTemporaryPassword = !showSendTemporaryPassword">
                    Ein-Mal-Passwort senden
                </OutlineButton>
                <OutlineButton class="ml-3" @click="sendWelcomeMail()" v-if="true || !item.gotWelcomeMail">
                    Willkommens E-Mail senden
                </OutlineButton>
            </div>

            <SendTemporaryPasswordConfirm
                :url="`/sales/customer/${props.id}/send-temporary-password`"
                :show="showSendTemporaryPassword"
                @close="showSendTemporaryPassword = false"
            >
                Das Passwort des Kunden wird geändert und eine E-Mail mit den neuen Zugangsdaten versendet. Der Kunde
                wird bei seinem nächsten Login aufgefordert, sein Passwort zu ändern.
            </SendTemporaryPasswordConfirm>
        </template>
    </ScShow>
</template>

<script setup lang="ts">
import type { Appointment } from "@/model/app/appointment"
import type { Lead } from "@/model/app/lead"
import { useHttpGet } from "@/vf"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import { useNow } from "@vueuse/core"
import { DateTime } from "luxon"
import { computed } from "vue"
import MonthlyCalendar from "../components/MonthlyCalendar.vue"
import PageHeader from "../components/PageHeader.vue"
import { calculateAgeFromBirthDay } from "../util/calculators"

const { data, loading } = useHttpGet<{ birthdays: Lead[]; appointments: Appointment[] }>("/sales/dashboard", false)

const now = useNow()
const relevantAppointments = computed(() => {
    return data.value?.appointments.filter(a => DateTime.fromISO(a.until!) > DateTime.fromJSDate(now.value))
})
</script>

<template>
    <div class="h-full flex flex-col">
        <PageHeader>
            <template #title>Dashboard</template>
        </PageHeader>

        <div class="flex flex-col gap-5 grow">
            <div class="flex gap-5">
                <RoundedCard color="secondary" class="flex-1">
                    <div class="card-title"><b>Geburtstage</b></div>

                    <div v-if="loading" class="w-1/2 h-5 bg-secondary-overlap animate-pulse"></div>
                    <div v-else-if="data?.birthdays.length === 0">Keine Geburtstage</div>
                    <div v-else>
                        <div v-for="lead of data?.birthdays" :key="lead.id!">
                            {{ lead.firstName }} {{ lead.lastName }} ({{ calculateAgeFromBirthDay(lead.birthdate!) }})
                        </div>
                    </div>
                </RoundedCard>

                <RoundedCard color="secondary" class="flex-1">
                    <div class="card-title"><b>Heutige Termine</b></div>

                    <div v-if="loading" class="w-1/2 h-5 bg-secondary-overlap animate-pulse"></div>
                    <div v-else-if="relevantAppointments?.length === 0">Keine Termine</div>
                    <div v-else>
                        <div v-for="appointment of relevantAppointments" :key="appointment.id!">
                            <span class="text-primary tabular-nums">
                                {{ DateTime.fromISO(appointment.from!).toFormat("HH:mm") }}
                            </span>
                            {{ appointment.lead?.firstName }} {{ appointment.lead?.lastName }}
                        </div>
                    </div>
                </RoundedCard>
            </div>

            <RoundedCard color="white" class="grow flex flex-col" default-class="h-full flex flex-col">
                <MonthlyCalendar class="grow" compact />
            </RoundedCard>
        </div>
    </div>
</template>

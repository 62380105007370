import { useHttpClient } from "@/vf"
import { ref, type Ref } from "vue"
import type { Router } from "vue-router"
import { vfConfig } from "../config/VfConfig"

const appConfig: Ref<AppConfig | null> = ref(null)
let _router: Router | null = null

async function refreshAppConfig() {
    if (_router) {
        return await loadInitialAppConfig(_router)
    }
}

async function loadInitialAppConfig(router: Router) {
    const httpClient = useHttpClient()
    let newAppConfig: AppConfig | null
    try {
        newAppConfig = (await httpClient.get<AppConfig>("/_app_config.json")).data
    } catch (e) {
        newAppConfig = null
    }

    _router = router

    vfConfig.reconfigureRoutes?.(router, newAppConfig?.routes ?? [])
    appConfig.value = newAppConfig
    ;(window as any).appConfig = newAppConfig

    return appConfig
}

export function useAppConfig<T = AppConfig>() {
    if (appConfig.value === null) {
        // somehow, in some cases (???), the appConfig variable is lost at hot reload
        appConfig.value = (window as any).appConfig
    }

    const customAppConfig = appConfig as Ref<T>

    return {
        appConfig: customAppConfig,
        refreshAppConfig,
        loadInitialAppConfig,
    }
}

export interface AppConfig {
    accesses: any
    account: any
    accountHierarchy: any
    languages: { iso: string; name: string }[]
    login: any
    menu: AppConfigMenuNode[]
    requiredConditions: any
    routes: any
    canManageFaq?: boolean
    switchFrom?: any
    isHeadJudge?: boolean
    panels?: { id: string; number: number }[]
}

export interface AppConfigMenuNode {
    id: string
    label: string
    params: Record<string, string>
    settings: Record<string, any>

    // node has either "children" or "route"
    children?: AppConfigMenuNode[]
    route?: string
}

<template>
    <div class="feedback-icon feedback-success feedback-animate-success-icon" style="display: flex">
        <!-- <div class="feedback-success-circular-line-left" :style="bgStyle" :class="bgClass"></div> -->
        <span class="feedback-success-line-tip"></span>
        <span class="feedback-success-line-long"></span>
        <div class="feedback-success-ring"></div>
        <!-- <div class="feedback-success-fix" :style="bgStyle" :class="bgClass"></div> -->
        <!-- <div class="feedback-success-circular-line-right" :style="bgStyle" :class="bgClass"></div> -->
    </div>
</template>

<style scoped>
.feedback-icon {
    position: relative;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 1.875em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    line-height: 5em;
    cursor: default;
    box-sizing: content-box;
    user-select: none;
    zoom: normal;
}

.feedback-icon.feedback-success {
    border-color: #3ac47d;
}

.feedback-icon.feedback-success [class^="feedback-success-circular-line"] {
    position: absolute;
    width: 3.75em;
    height: 7.5em;
    transform: rotate(45deg);
    border-radius: 50%;
}

.feedback-icon.feedback-success [class^="feedback-success-circular-line"][class$="left"] {
    top: -0.4375em;
    left: -2.0635em;
    transform: rotate(-45deg);
    transform-origin: 3.75em 3.75em;
    border-radius: 7.5em 0 0 7.5em;
}

.feedback-icon.feedback-success [class^="feedback-success-circular-line"][class$="right"] {
    top: -0.6875em;
    left: 1.875em;
    transform: rotate(-45deg);
    transform-origin: 0 3.75em;
    border-radius: 0 7.5em 7.5em 0;
}

.feedback-icon.feedback-success .feedback-success-ring {
    position: absolute;
    top: -0.25em;
    left: -0.25em;
    width: 100%;
    height: 100%;
    border: 0.25em solid rgba(165, 220, 134, 0.3);
    border-radius: 50%;
    z-index: 2;
    box-sizing: content-box;
}

.feedback-icon.feedback-success .feedback-success-fix {
    position: absolute;
    top: 0.5em;
    left: 1.625em;
    width: 0.4375em;
    height: 5.625em;
    transform: rotate(-45deg);
    z-index: 1;
}

.feedback-icon.feedback-success [class^="feedback-success-line"] {
    display: block;
    position: absolute;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #3ac47d;
    z-index: 2;
}

.feedback-icon.feedback-success [class^="feedback-success-line"][class$="tip"] {
    top: 2.875em;
    left: 0.875em;
    width: 1.5625em;
    transform: rotate(45deg);
}

.feedback-icon.feedback-success [class^="feedback-success-line"][class$="long"] {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
    transform: rotate(-45deg);
}

.feedback-animate-success-icon .feedback-success-line-tip {
    animation: feedback-animate-success-line-tip 0.75s;
}

.feedback-animate-success-icon .feedback-success-line-long {
    animation: feedback-animate-success-line-long 0.75s;
}

.feedback-animate-success-icon .feedback-success-circular-line-right {
    animation: feedback-rotate-success-circular-line 4.25s ease-in;
}

.feedback-animate-error-icon {
    animation: feedback-animate-error-icon 0.5s;
}

.feedback-animate-error-icon .feedback-x-mark {
    animation: feedback-animate-error-x-mark 0.5s;
}

@keyframes feedback-animate-success-line-tip {
    0% {
        top: 1.1875em;
        left: 0.0625em;
        width: 0;
    }
    54% {
        top: 1.0625em;
        left: 0.125em;
        width: 0;
    }
    70% {
        top: 2.1875em;
        left: -0.375em;
        width: 3.125em;
    }
    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em;
    }
    100% {
        top: 2.8125em;
        left: 0.875em;
        width: 1.5625em;
    }
}

@keyframes feedback-animate-success-line-long {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }
    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }
    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em;
    }
    100% {
        top: 2.375em;
        right: 0.5em;
        width: 2.9375em;
    }
}

@keyframes feedback-rotate-success-circular-line {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}
</style>

<script lang="ts" setup>
import { injectMandatory, useModuleAction, type Entity } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"

const item = injectMandatory<Entity>("item", "VfEditButton requires an item to be injected")
const moduleAction = useModuleAction()
</script>

<template>
    <FilledButton @click="moduleAction.open(item, 'edit')" color="primary">Bearbeiten</FilledButton>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router"
import SalesSidebar from "./components/SalesSidebar.vue"
const router = useRouter()
await router.isReady()
</script>

<template>
    <RouterView v-slot="{ Component, route }">
        <Transition
            :leave-active-class="
                route.meta.loginTransition ? 'transition transition-500' : 'transition duration-500 isolate z-50'
            "
            :leave-from-class="route.meta.loginTransition ? '' : 'translate-y-0'"
            :leave-to-class="route.meta.loginTransition ? '' : '-translate-y-[100vh]'"
            :enter-active-class="route.meta.loginTransition ? 'transition duration-500 isolate z-50' : ''"
            :enter-from-class="route.meta.loginTransition ? '-translate-y-[100vh]' : ''"
            :enter-to-class="route.meta.loginTransition ? 'translate-y-0' : ''"
            :duration="500"
            mode="default"
        >
            <div v-if="!route.meta.loginTransition" class="min-h-screen flex flex-row justify-items-stretch stretch">
                <SalesSidebar class="w-2/12 shrink-0" v-if="!route.meta.hideLayout"></SalesSidebar>
                <div class="p-5 grow flex-col bg-modal">
                    <Suspense>
                        <component :is="Component" />
                    </Suspense>
                </div>
            </div>
            <div v-else class="flex flex-row h-screen z-50 isolate fixed top-0 left-0 right-0 bottom-0">
                <Transition :duration="500">
                    <component :is="Component" />
                </Transition>
            </div>
        </Transition>
    </RouterView>
</template>

export function createSignaler<Signals extends Record<string, (...args: any) => void>>() {
    // the list of parameters for a signal
    type SignalParameters<Signal extends keyof Signals = keyof Signals> = Parameters<Signals[Signal]>

    // create a type with all known signals, but force a promise of the original return type, unless the original
    // return type is void, in which case we keep void
    type SignalsWithPromiseReturns = {
        [Signal in keyof Signals]: Signals[Signal] extends (...args: any) => infer R
            ? R extends void
                ? (...args: SignalParameters<Signal>) => void
                : R extends Promise<any>
                ? (...args: SignalParameters<Signal>) => R
                : (...args: SignalParameters<Signal>) => Promise<R>
            : never
    }

    let handlers = {} as Signals
    const queue = [] as [keyof Signals, SignalParameters][]

    // hook() is called by the component that wants to receive signals. If there were signals sent before the hook was
    // called, they are replayed.
    function hook(_handlers: Signals) {
        handlers = _handlers

        for (const [signal, args] of queue) {
            handlers[signal].apply(undefined, args)
        }

        queue.splice(0, queue.length)
    }

    function signal<Signal extends keyof Signals>(signal: Signal, ...args: SignalParameters<Signal>): void {
        if (handlers[signal]) {
            handlers[signal].apply(undefined, args)
        } else {
            queue.push([signal, args])
        }
    }

    // allows calling signaler.foo() instead of signaler.signal("foo")
    const proxy = new Proxy(
        { hook, signal },
        {
            get(target, prop) {
                if (prop === "hook" || prop === "signal") {
                    return target[prop]
                }

                return (...args: SignalParameters) => signal(prop as keyof Signals, ...args)
            },
        },
    )

    return proxy as SignalsWithPromiseReturns & { hook: typeof hook; signal: typeof signal }
}

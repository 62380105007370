<script setup lang="ts"></script>

<template>
    <div class="-m-5 -mb-10 p-10 pb-20 bg-secondary-tint">
        <div class="flex">
            <Transition
                appear
                enter-active-class="transition duration-300"
                enter-from-class="-translate-x-5 opacity-0"
                enter-to-class="translate-x-0 opacity-100"
            >
                <div class="text-4xl text-modal font-bold mb-5 grow">
                    <slot name="title"></slot>
                </div>
            </Transition>

            <slot name="search"></slot>
        </div>

        <Transition
            appear
            enter-active-class="transition duration-300"
            enter-from-class="translate-y-5 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
        >
            <div>
                <slot name="buttons"></slot>
            </div>
        </Transition>
    </div>
</template>

import { whenever } from "@vueuse/core"
import { ref } from "vue"

export function useEnsureLoaded(name: string, refreshFn: () => Promise<void>) {
    const state = ref<"empty" | "loading" | "loaded">("empty")

    function ensureLoaded(): Promise<void> {
        switch (state.value) {
            case "empty":
                console.log(`[${name}] empty, refreshing`)
                return refresh()

            case "loading":
                console.log(`[${name}] loading, waiting for pending request`)
                return new Promise(resolve =>
                    whenever(
                        () => state.value === "loaded",
                        () => resolve(),
                    ),
                )

            case "loaded":
            default:
                console.log(`[${name}] already loaded`)
                return Promise.resolve()
        }
    }

    async function refresh() {
        if (state.value === "loading") {
            return ensureLoaded()
        }

        await refreshFn()

        state.value = "loaded"
    }

    return { state, ensureLoaded, refresh }
}

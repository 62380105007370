import type { Entity } from "@/vf"
import type { Component } from "vue"
import { defineAsyncComponent, ref } from "vue"
import {
    normalizeListFieldGroups,
    normalizeListOnClick,
    normalizeShowFieldGroupsHeadRow,
    normalizeShowOpenRowAsTab,
} from "./list-normalizer"
import type {
    ListFieldConfiguration,
    ModuleConfiguration,
    ModuleConfigurationOptions,
    NormalizedCrudViewModelInterface,
    NormalizedListFieldGroupConfiguration,
    PageConfig,
    PageConfigFn,
} from "./types"
import { isVueComponent } from "./util"

export type { ListFilter, ListRequest, ListRequestParams } from "./types"
export type {
    ListFieldConfiguration,
    ModuleConfiguration,
    ModuleConfigurationOptions,
    NormalizedCrudViewModelInterface,
    NormalizedListFieldGroupConfiguration,
}

//const i18n = useI18n()

function normalizeActionConfig(
    normalized: Partial<ModuleConfiguration>,
    options: ModuleConfigurationOptions,
    which: "edit" | "new" | "show",
): (entity?: Entity) => NormalizedCrudViewModelInterface {
    const defaultMode = options.mode ?? "router"
    const actionComponentName = which === "new" ? "edit" : which

    const defaultActionConfig = (entity?: Entity) => {
        return {
            component: actionComponentName,
            routePart: which,
            props: normalized.routeParams!(entity),
            mode: defaultMode,
            rowId: entity?.id,
            entity: entity,
        }
    }

    // resolve the PageConfig | { page: PageConfig, ...buttons } to just PageConfig
    const providedPageConfig: PageConfig | undefined =
        (typeof options[which] == "object" ? (options[which] as { page: PageConfig })?.page : undefined) ??
        (options[which] as PageConfig)

    if (providedPageConfig) {
        return (entity): NormalizedCrudViewModelInterface => {
            // use as is if it is a PageConfig
            let result: PageConfig

            // otherwise, create a PageConfig from the function
            if (typeof providedPageConfig === "function") {
                result = (providedPageConfig as PageConfigFn)(entity)
            } else {
                result = providedPageConfig
            }

            // treat a string result as a component name
            if (typeof result === "string") {
                return {
                    component: result,
                    routePart: result,
                    props: normalized.routeParams!(entity),
                    mode: defaultMode,
                    rowId: entity?.id,
                    entity: entity,
                }
            }

            // result is a component
            if (isVueComponent(result)) {
                return {
                    component: result,
                    props: normalized.routeParams!(entity),
                    mode: defaultMode,
                    rowId: entity?.id,
                    entity: entity,
                }
            }

            if (!result) {
                return defaultActionConfig(entity)
            }

            return {
                props: null,
                mode: options.mode ?? "router",
                rowId: entity?.id,
                entity: entity,
                ...result,
            }
        }
    }

    return defaultActionConfig
}

function normalizeButtonVisibility(
    options: ModuleConfigurationOptions,
    obj: any,
    field: "deleteButton" | "showButton" | "editButton" | "backButton" | "buttonColumn" | "newButton",
    defaultValue: boolean,
) {
    // check if the button is configured on the action level (list.deleteButton, show.deleteButton, edit.deleteButton etc)
    // and if that is the case, use that
    if (typeof obj === "object" && obj[field] !== undefined) {
        return typeof obj[field] === "function" ? obj[field] : () => obj[field]
    }

    // if it is not defined on the action level, check if there is one configured on the ModuleConfigurationOptions root level
    // ($config.deleteButton)
    if (options[field] !== undefined) {
        return typeof options[field] === "function" ? options[field] : () => options[field]
    }

    // if it is not configured at all, use the default value
    return () => defaultValue
}

export function normalizeModuleConfiguration(options: ModuleConfigurationOptions): ModuleConfiguration {
    const apiBaseAtNormalizationTime = options.apiBase ? options.apiBase() : ""
    // noinspection JSUnusedLocalSymbols
    const config: Partial<ModuleConfiguration> = {
        safeDeleteDialog: {
            confirmationProperty: entity => entity.name ?? entity.title ?? entity.subject ?? entity.id,
            active: false,
            prompt: () => "crud:delete.safe_delete_prompt",
            // i18n.t("crud:delete.safe_delete_prompt", {
            //     value: this.config.safeDeleteDialog.confirmationProperty(entity),
            // }),
        },
        list: {
            id: () => location.pathname + "-" + apiBaseAtNormalizationTime,
            onClick: (entity: Entity) => {
                return config["show"]!.page(entity)
            },
            rowClickable: () => true,
            defaultItemsPerPage: 20,
            fieldGroups: [],
            showFieldGroupsHeadRow: false,
            showOpenRowAsTab: false,
            filterFields: [],
            orderDirection: "asc",
            orderField: null,
            newButton: () => true,
            showButton: () => true,
            editButton: () => true,
            deleteButton: () => true,
            buttonColumn: () => true,
            requestParams: ref({}),
            trClass: () => ({}),
            fieldsCustomizable: false,
            defaultFilter: () => ({}),
            onRequest: params => params,
        },
        apiBase: () => {
            throw Error("you must implement config.apiBase()")
        },
        routeParams: entity => {
            if (entity && entity.id) {
                return { id: entity.id }
            }
            return {}
        },
        entityPath: entity => {
            let url = config!.apiBase!()
            if (!url.endsWith("/")) {
                url += "/"
            }

            // das war der alte check, keine ahnung wieso, falls das jetzt probleme macht wars vllt doch sinnvoll
            // } else if ("" + entity === "" + parseInt(entity, 10) || typeof entity === "string") {

            switch (typeof entity) {
                case "number":
                case "string":
                    url += `${entity}`
                    break

                case "object":
                    // wtf javascript?!
                    if (entity !== null && entity.id) {
                        url += `${entity.id}`
                    }

                    break
            }

            return url
        },
        afterSaveAction: "auto",
        afterDeleteAction: "auto",
        routePrefix: () => {
            return undefined
        },
        readableName: {
            plural: "<config.readableName.plural>",
            singular: "<config.readableName.singular>",
            entity(entity) {
                return entity.name ?? entity.title ?? entity.subject ?? entity.id ?? ""
            },
        },
    }
    // const normalized: ModuleConfiguration = {}
    config.safeDeleteDialog = {
        confirmationProperty:
            options.safeDeleteDialog?.confirmationProperty ?? config!.safeDeleteDialog!.confirmationProperty,
        active: options.safeDeleteDialog?.active ?? false,
        prompt: options.safeDeleteDialog?.prompt ?? config!.safeDeleteDialog!.prompt,
    }

    config.edit = {
        page: normalizeActionConfig(config, options, "edit"),
        backButton: normalizeButtonVisibility(options, options?.edit, "backButton", true),
        deleteButton: normalizeButtonVisibility(options, options.edit, "deleteButton", true),
    }

    config.show = {
        page: normalizeActionConfig(config, options, "show"),
        backButton: normalizeButtonVisibility(options, options.show, "backButton", true),
        deleteButton: normalizeButtonVisibility(options, options.show, "deleteButton", true),
        editButton: normalizeButtonVisibility(options, options.show, "editButton", true),
    }

    config.new = {
        page: normalizeActionConfig(config, options, "new"),
        backButton: normalizeButtonVisibility(options, options.new, "backButton", true),
    }

    // cache defined components (defineAsyncComponent below) so we get the same instance every time. This is required
    // to determine if we want to open the same component in inline tables in order for toggle to work.
    const _componentCache = new Map<string, Component>()
    config.resolveComponent =
        options.resolveComponent ??
        ((cvm: NormalizedCrudViewModelInterface) => {
            // recursively resolve components (e.g. show: 'edit')
            while (typeof cvm.component === "string") {
                if (typeof config[cvm.component as "edit" | "show" | "new"] === "object") {
                    const oldCvm = cvm
                    cvm = config[cvm.component as "edit" | "show" | "new"]!.page(cvm.entity!)
                    if (oldCvm.component === cvm.component) {
                        break
                    }
                } else {
                    break
                }
            }
            if (isVueComponent(cvm.component)) {
                return cvm.component
            }

            const routePrefix = config.routePrefix!()

            if (routePrefix === undefined) {
                // we used to throw an error here, but we rarely ever rely on this feature (automatically resolve
                // "edit" page, for example, by concatenating a path), so we just return null instead, which
                // triggers a close of the inline table.
                return null
                // throw Error("you must implement config.routePrefix() to use automatic component loading")
            }

            const filePath = routePrefix.replace(/^@/, "").replaceAll(".", "/") + "/" + cvm.component
            const pathParts = filePath.split("/")
            const dirCount = pathParts.length - 1

            let component
            // this strange construct is to allow vite to resolve the component in build/hmr
            if (dirCount == 0) {
                component = () => import(`../../pages/${filePath}.vue`)
            } else if (dirCount == 1) {
                component = () => import(`../../pages/${pathParts[0]}/${pathParts[1]}.vue`)
            } else if (dirCount == 2) {
                component = () => import(`../../pages/${pathParts[0]}/${pathParts[1]}/${pathParts[2]}.vue`)
            } else if (dirCount == 3) {
                component = () =>
                    import(`../../pages/${pathParts[0]}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}.vue`)
            } else {
                throw Error("Too many path parts. You need to add more import statements to support this path.")
            }

            if (!_componentCache.has(filePath)) {
                _componentCache.set(filePath, defineAsyncComponent(component))
            }

            return _componentCache.get(filePath)!
        })

    config.apiBase = options.apiBase ?? config.apiBase
    config.routeParams = options.routeParams ?? config.routeParams
    config.entityPath = options.entityPath ?? config.entityPath
    config.afterSaveAction = options.afterSaveAction === undefined ? config.afterSaveAction : options.afterSaveAction
    config.afterDeleteAction = options.afterDeleteAction ?? config.afterDeleteAction
    config.routePrefix = options.routePrefix ?? config.routePrefix

    const providedReadableName = options.readableName
    if (typeof providedReadableName === "string") {
        config.readableName = {
            plural: providedReadableName,
            singular: providedReadableName,
            entity: () => config.readableName!.singular,
        }
    } else {
        Object.assign(config.readableName!, options.readableName)
    }

    const normalizedFieldGroups = normalizeListFieldGroups(options.list?.fieldGroups, options.list?.fields)
    config.list = {
        id: options.list?.id ?? config.list!.id,
        onClick: normalizeListOnClick(config, options),
        rowClickable:
            options.list?.rowClickable ??
            // onClick is set to null, so row is not clickable
            (options.list?.onClick === null ? () => false : null) ??
            config.list!.rowClickable,
        defaultItemsPerPage: options.list?.defaultItemsPerPage ?? config.list!.defaultItemsPerPage,
        fieldGroups: normalizedFieldGroups,
        showFieldGroupsHeadRow: normalizeShowFieldGroupsHeadRow(
            options.list?.showFieldGroupsHeadRow,
            normalizedFieldGroups,
        ),
        showOpenRowAsTab: normalizeShowOpenRowAsTab(options.list?.showOpenRowAsTab, normalizedFieldGroups),
        filterFields: options.list?.filterFields ?? config.list!.filterFields,
        orderField: options.list?.orderField ?? config.list!.orderField,
        orderDirection: options.list?.orderDirection ?? config.list!.orderDirection,
        newButton: normalizeButtonVisibility(options, options.list, "newButton", true),
        showButton: normalizeButtonVisibility(options, options.list, "showButton", true),
        editButton: normalizeButtonVisibility(options, options.list, "editButton", true),
        deleteButton: normalizeButtonVisibility(options, options.list, "deleteButton", true),
        buttonColumn: normalizeButtonVisibility(options, options.list, "buttonColumn", options.mode != "in-table"),
        requestParams: options.list?.requestParams ?? config.list!.requestParams,
        defaultFilter: options.list?.defaultFilter ?? config.list!.defaultFilter,
        onRequest: options.list?.onRequest ?? config.list!.onRequest,
        trClass: entity => {
            const input: string | string[] = options.list?.trClass?.(entity) ?? ""

            // normalize input to array of class names
            const normalized: string[] = (input instanceof Array ? input : input.split(" ")).map(s => s.trim())
            const output: Record<string, true> = {}

            // v-bind:class expects an object with class names as keys and boolean values
            // if string values are needed, you can still use Object.keys()
            normalized.forEach(c => {
                output[c] = true
            })

            return output
        },
        fieldsCustomizable:
            options.list?.fieldsCustomizable ??
            normalizedFieldGroups.reduce((groupAcc, fieldGroup) => {
                return fieldGroup.fields.reduce((acc, field) => acc || !!field.defaultHidden, groupAcc)
            }, false),
    }

    if (config.list!.orderField === null) {
        const firstSortableField = config.list!.fieldGroups[0]?.fields?.filter(field => !!field.sortField).shift()

        if (firstSortableField) {
            config.list!.orderField = firstSortableField.name
        }
    }

    return config as ModuleConfiguration
}

import { useHttpClient } from "@/vf"
import type { MaybeRef } from "@vueuse/core"
import { isRef, ref, unref, watch, type Ref } from "vue"

export interface UseHttpGetReturn<T> {
    /** @deprecated, use data instead */
    content: Ref<T | undefined>
    data: Ref<T | undefined>
    loading: Ref<boolean>
    statusCode: Ref<number | undefined>
    refresh: () => Promise<void>
}

export function useHttpGet<T>(url: MaybeRef<string>, awaitInitialRequest: true): Promise<UseHttpGetReturn<T>>
export function useHttpGet<T>(url: MaybeRef<string>, awaitInitialRequest: false): UseHttpGetReturn<T>
export function useHttpGet<T>(url: MaybeRef<string>): UseHttpGetReturn<T> | Promise<UseHttpGetReturn<T>>

export function useHttpGet<T>(
    url: MaybeRef<string>,
    awaitInitialRequest = true,
): UseHttpGetReturn<T> | Promise<UseHttpGetReturn<T>> {
    const http = useHttpClient()
    const content = ref<T>()
    const loading = ref<boolean>(true)
    const statusCode = ref<number>()

    async function refresh() {
        loading.value = true

        try {
            const response = await (awaitInitialRequest ? http.get : http.getBg)<T>(unref(url))
            content.value = response.data
            statusCode.value = response.status
        } catch {
            content.value = undefined
            statusCode.value = undefined
        }

        loading.value = false
    }

    if (isRef(url)) {
        watch(url, () => refresh())
    }

    if (awaitInitialRequest) {
        return refresh().then(() => ({ content, loading, statusCode, refresh, data: content }))
    } else {
        refresh().then()
        return { content, loading, statusCode, refresh, data: content }
    }
}

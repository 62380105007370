<template>
    <div class="feedback-loading">
        <div class="spinner"></div>
    </div>
</template>

<!--<style lang="scss" scoped>-->
<!--@import "@/styles/variables";-->

<!--.feedback-loading {-->
<!--    text-align: center;-->

<!--    // to match other feedback elements:-->
<!--    height: 5em;-->
<!--    width: 5em;-->
<!--    margin: 1.25em auto 1.875em;-->
<!--    border: 0.25em solid transparent;-->
<!--    box-sizing: content-box;-->
<!--    transform: translate3d(-3px, -3px, 0);-->

<!--    .spinner {-->
<!--        width: 5rem;-->
<!--        height: 5rem;-->

<!--        background-color: $secondary;-->
<!--        animation: flip 1.2s ease infinite;-->
<!--        //border-radius: 0.25rem;-->
<!--    }-->
<!--}-->

<!--@keyframes flip {-->
<!--    0% {-->
<!--        transform: perspective(120px) rotateX(0deg) rotateY(0deg);-->
<!--    }-->
<!--    50% {-->
<!--        transform: perspective(120px) rotateX(-180deg) rotateY(0deg);-->
<!--        //border-radius: 50%;-->
<!--    }-->
<!--    100% {-->
<!--        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);-->
<!--    }-->
<!--}-->
<!--</style>-->

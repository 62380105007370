<script lang="ts" setup>
import { injectMandatory, type Entity, type ModuleConfiguration } from "@/vf"
import { computed } from "vue"
import { ScBackButton, ScDeleteButton, ScSaveButton } from "."

const props = defineProps<{
    item: Entity
}>()
const config = injectMandatory<ModuleConfiguration>("module-config", "ScEditToolbar must be used inside ScEdit")

const mode = computed(() => config.show.page(props.item).mode)
</script>

<template>
    <div class="flex justify-between">
        <ScBackButton v-if="config.edit.backButton() && mode !== 'router'" />
        <ScDeleteButton v-if="config.edit.deleteButton(item)" />
        <ScSaveButton />
    </div>
</template>

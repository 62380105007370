<script setup lang="ts">
import type { Agent } from "@/model/app/agent"
import { ScEdit } from "@/sales/components/crud"
import { useLoadEntity } from "@/vf"
import { createModuleConfiguration } from "./agents.config"

const props = defineProps<{ id?: string }>()
const config = createModuleConfiguration()
const { item } = await useLoadEntity<Agent>(props.id, config)
</script>

<template>
    <ScEdit v-bind="{ config, item }"></ScEdit>
</template>

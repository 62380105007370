<script setup lang="ts">
import { isNumericKey } from "@/common/util/format"
import type { TokenPrice } from "@/model/app/token-price"
import { ScInlineList } from "@/sales/components/crud"
import PageHeader from "@/sales/components/PageHeader.vue"
import { $currency, useHttpClient, useHttpGet } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import { ref } from "vue"
import { createModuleConfiguration } from "./token-prices.config"

const config = createModuleConfiguration()
const { content, refresh, loading } = useHttpGet<TokenPrice>("/sales/token-price/latest", false)

const list = ref<typeof ScInlineList>()
const newEuroCentsPerToken = ref<number>()
const { post } = useHttpClient()

async function saveNewPricePerToken() {
    if (newEuroCentsPerToken.value === undefined) {
        return
    }

    await post("/sales/token-price/", { euroCentsPerToken: newEuroCentsPerToken.value * 100 })
    refresh()
    list.value?.refresh()
    newEuroCentsPerToken.value = undefined
}
</script>

<template>
    <div>
        <PageHeader>
            <template #title>Preise</template>
        </PageHeader>
        <RoundedCard>
            <div class="flex gap-16">
                <div>
                    <div class="card-title">Aktueller Preis</div>
                    <div>
                        <div v-if="loading" class="h-12 w-32 bg-secondary opacity-10 animate-pulse"></div>
                        <div v-else class="text-primary text-5xl">{{ $currency(content?.euroCentsPerToken) }}</div>
                    </div>

                    <form @submit.prevent="saveNewPricePerToken" class="mt-10">
                        <div class="card-title">Neuer Preis</div>

                        <input
                            type="number"
                            step="0.01"
                            class="input-field"
                            @keypress="isNumericKey"
                            v-model.number="newEuroCentsPerToken"
                        />

                        <FilledButton type="submit" color="primary">Speichern</FilledButton>
                    </form>
                </div>

                <div class="grow">
                    <div class="card-title">Preisverlauf</div>
                    <ScInlineList v-bind="{ config }" ref="list">
                        <template #title>Preise</template>
                    </ScInlineList>
                </div>
            </div>
        </RoundedCard>
    </div>
</template>

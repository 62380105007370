<script lang="ts" setup>
import type { Entity, ModuleConfiguration } from "@/vf"
import type { ListState } from "@/vf/components/crud/list/types"
import { toRefs } from "vue"

const props = defineProps<{
    config: ModuleConfiguration
    listState: ListState
    item: Entity
}>()
const { config, listState } = toRefs(props)
</script>

<template>
    <button
        v-if="config.list.showButton(item)"
        :title="$t('crud:list.show')"
        class="btn btn-secondary"
        type="button"
        @click="listState.columnClicked(item, 'button:show')"
    >
        <i class="fa fa-fw fa-eye"></i>
    </button>
    <button
        v-if="config.list.editButton(item)"
        :title="$t('crud:list.edit')"
        type="button"
        class="btn btn-secondary"
        @click="listState.editEntity(item)"
    >
        <i class="fa fa-fw fa-pencil"></i>
    </button>
    <button
        v-if="config.list.deleteButton(item)"
        :title="$t('crud:list.delete')"
        type="button"
        class="btn btn-danger"
        @click="listState.deleteEntity(item)"
    >
        <i class="fa fa-fw fa-trash"></i>
    </button>
</template>

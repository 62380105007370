<script setup lang="ts">
import { VfList, type ModuleConfiguration } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import { ref, toRefs } from "vue"
import PageHeader from "../PageHeader.vue"
import ScListCustomizeFields from "./ScListCustomizeFields.vue"
import ScListPagination from "./ScListPagination.vue"
import ScListSearch from "./ScListSearch.vue"
import ScListTable from "./ScListTable.vue"
import ScListToolbar from "./ScListToolbar.vue"

const props = withDefaults(defineProps<{ config: ModuleConfiguration; disableSearch?: boolean }>(), {
    disableSearch: false,
})
const { config } = toRefs(props)

const customizeFieldsOpen = ref(false)
</script>

<template>
    <div>
        <VfList :await="false" v-bind="{ config }" v-slot="{ listState, fields, filteredFieldGroups }">
            <PageHeader>
                <template #title><slot name="title"></slot></template>
                <template #buttons>
                    <ScListToolbar />
                    <OutlineButton
                        color="primary"
                        @click="customizeFieldsOpen = !customizeFieldsOpen"
                        :active="customizeFieldsOpen"
                        v-if="config.list.fieldsCustomizable"
                    >
                        Anpassen
                    </OutlineButton>
                </template>
                <template #search>
                    <ScListSearch v-if="!disableSearch" />
                    <slot name="custom-filter"></slot>
                </template>
            </PageHeader>

            <div v-animate-show="customizeFieldsOpen" class="-m-2">
                <RoundedCard color="white" class="m-2 mb-5">
                    <div class="card-title">
                        <b>Tabelle</b>
                        anpassen
                    </div>
                    <ScListCustomizeFields />
                </RoundedCard>
            </div>

            <RoundedCard>
                <div class="overflow-x-auto relative">
                    <ScListTable v-bind="{ config, listState, fields, filteredFieldGroups }" />
                </div>
                <div class="mt-5">
                    <template v-if="!listState.loading">
                        <ScListPagination />
                    </template>
                </div>
            </RoundedCard>
        </VfList>
    </div>
</template>

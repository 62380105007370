import { inject, type InjectionKey } from "vue"

export function injectMandatory<T>(injectKey: InjectionKey<T> | string, errorMessage: string): T {
    const injectedValue = inject<T | undefined>(injectKey, undefined)

    if (injectedValue === undefined) {
        throw new Error(errorMessage)
    }

    return injectedValue
}

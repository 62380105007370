<script lang="ts" setup>
import type { Entity } from "@/vf"
import { injectMandatory, useModuleAction } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"

const item = injectMandatory<Entity>("item", "VfBackButton requires an item to be injected")
const moduleAction = useModuleAction()
// const currentPage: PageType = inject("current-page", "show")

// const config = injectMandatory<ModuleConfiguration>(
//     "module-config",
//     "VfBackButton must be used inside an element that provides module-config like VfEdit or VfShow",
// )

// const mode = config.show.page(item).mode
</script>

<template>
    <OutlineButton color="primary" @click="moduleAction.toggle(item, 'show')">Zurück</OutlineButton>
</template>

import { defineStore } from "pinia"
import { reactive } from "vue"

export interface Toast {
    type: string
    title: string
    message: string
    alert_class: string
    decay: number | undefined
    buttons: any
}

export type ToastType = "success" | "warning" | "error"

export const useToaster = defineStore("toaster", () => {
    const toasts = reactive<Toast[]>([])

    /**
     * Type can be "success", "warning" or "error".
     * Decay is the number of milliseconds after which the toast gets closed automatically.
     */
    function add(type: ToastType, title: string, message: string, decay?: number, buttons?: any) {
        if (decay === undefined && type === "success") {
            decay = 5000
        }

        const toast: Toast = {
            type: type,
            title: title,
            message: message,
            alert_class: getClassForType(type),
            decay: decay,
            buttons: buttons,
        }

        toasts.push(toast)

        if (decay) {
            setTimeout(() => {
                remove(toast)
            }, decay)
        }

        return toast
    }

    function remove(toast: Toast) {
        const idx = toasts.indexOf(toast)

        if (idx >= 0) {
            toasts.splice(idx, 1)
        }
    }

    function clear() {
        toasts.splice(0)
    }

    function getClassForType(type: ToastType) {
        if (["success", "info", "warning"].includes(type)) {
            return "alert-" + type
        }

        return "alert-danger"
    }

    return {
        add,
        remove,
        clear,
        getClassForType,
        toasts,
    }
})

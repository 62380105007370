<script setup lang="ts">
import AutoHeightTextArea from "@/common/components/AutoHeightTextArea.vue"
import { useHttpClient } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { ref } from "vue"

const props = defineProps<{ leadId: string }>()
const emit = defineEmits<{
    (e: "created"): void
}>()
const { post } = useHttpClient()
const text = ref("")

async function submit() {
    if (text.value.trim() === "") {
        return
    }

    await post(`/sales/lead/${props.leadId}/note/`, { text })
    text.value = ""
    emit("created")
}
</script>

<template>
    <form class="flex gap-3" @submit.prevent="submit">
        <AutoHeightTextArea v-model="text" class="w-full" placeholder="Neue Notiz schreiben..."></AutoHeightTextArea>
        <OutlineButton @click="submit">Speichern</OutlineButton>
    </form>
</template>

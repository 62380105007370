import { computed, ref } from "vue"

export function useChangePassword() {
    const newPassword = ref("")
    const confirmPassword = ref("")

    const passwordsMatch = computed(() => confirmPassword.value === "" || newPassword.value === confirmPassword.value)
    const isValid = computed(() => newPassword.value !== "" && newPassword.value === confirmPassword.value)

    return {
        newPassword,
        confirmPassword,
        passwordsMatch,
        isValid,
    } as const
}

<script lang="ts" setup>
import gsap from "gsap"
import { nextTick, onMounted, ref, watch } from "vue"

const props = defineProps<{
    if?: boolean | undefined | null
    animateOnInit?: boolean
}>()

const emit = defineEmits<{
    (e: "mounted"): void
    (e: "unmounted"): void
    (e: "opened"): void
    (e: "closed"): void
}>()

let showWithoutAnimation = !!props.if && !props.animateOnInit

function enter(el: HTMLElement) {
    gsap.to(el, {
        duration: 0.5,
        height: "auto",
        onComplete: () => {
            el.style.overflowY = "visible"
        },
    }).then(() => emit("opened"))
}

async function beforeLeave(el: HTMLElement) {
    await gsap.to(el, {
        duration: 0.5,
        height: 0,
        onStart: () => {
            if (el) {
                el.style.overflowY = "hidden"
            }
        },
    })
    emit("closed")
}

const mount = ref(false)
const ele = ref<HTMLElement>()

async function show() {
    await nextTick()

    if (showWithoutAnimation) {
        showWithoutAnimation = false
        ele.value!.style.height = "auto"
        return
    }

    enter(ele.value!)
}

let lock = false

async function conditionChanged(shouldShow: boolean) {
    if (lock) {
        return
    }
    lock = true
    if (shouldShow) {
        mount.value = true
        emit("mounted")
    } else {
        await beforeLeave(ele.value!)
        await nextTick()
        mount.value = false
        emit("unmounted")
    }
    lock = false
    if (shouldShow != props.if) {
        // if we are here, it means that the condition changed while we were animating
        await nextTick()
        await conditionChanged(!!props.if)
    }
}

watch(
    () => props.if,
    condition => conditionChanged(!!condition),
)
onMounted(() => {
    // only call conditionChanged when true, not when false
    if (props.if) {
        conditionChanged(true)
    }
})
</script>

<template>
    <template v-if="mount">
        <Suspense @resolve="show">
            <div ref="ele" style="height: 0; overflow-y: hidden; padding: 0 !important">
                <slot></slot>
            </div>
        </Suspense>
    </template>
</template>

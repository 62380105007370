// modified version of https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data
export function objectToFormData(obj: any, rootName = "") {
    const formData = new FormData()

    function appendFormData(data: any, root: string) {
        root = root || ""
        if (data instanceof File) {
            formData.append(root, data)
        } else if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                appendFormData(data[i], root + "[" + i + "]")
            }
        } else if (typeof data === "boolean") {
            if (data) {
                formData.append(root, "1")
            } else {
                // don't set the field
            }
        } else if (typeof data === "object" && data) {
            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    if (root === "") {
                        if (Array.isArray(data[key]) && data[key].length == 1 && data[key][0]?.file instanceof File) {
                            // single file
                            formData.append(key, data[key][0].file)
                        } else {
                            appendFormData(data[key], key)
                        }
                    } else {
                        appendFormData(data[key], root + "[" + key + "]")
                    }
                }
            }
        } else {
            if (data !== null && typeof data !== "undefined" && root[0] != "$") {
                formData.append(root, data)
            }
        }
    }

    appendFormData(obj, rootName)

    return formData
}
